// import React, { useState } from 'react'
// import Layout from '../Layout/Layout'
// import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { baseUrl } from '../Redux/Slice/Aadhar';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import forge from 'node-forge'


// const useStyles = makeStyles((theme) => ({
//     formContainer: {
//         padding: theme.spacing(4),
//         backgroundColor: '#f5f5f5',
//         borderRadius: '8px',
//     },
//     formField: {
//         margin: theme.spacing(2, 0),
//     },
//     submitButton: {
//         marginTop: theme.spacing(4),
//         backgroundColor: '#007bff',
//         color: '#fff',
//         '&:hover': {
//             backgroundColor: '#0056b3',
//         },
//     },
//     formTitle: {
//         marginBottom: theme.spacing(2),
//         color: '#007bff',
//     },
// }));
// const CreateAbha = () => {
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const [AbhaNumber, setAbhaNumber] = useState('');
//     const [otp, setotp] = useState('');
//     const [txnId, setTxnId] = useState(false); // State for transaction ID
//     const [mobileNo, setMobileNo] = useState('');
//     const [Abha, setAbha] = useState('');
//     const [Aadhaar, setAadhaar] = useState('');


//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const formData = new FormData();
//         formData.append('AbhaAddress', AbhaNumber);

//         try {
//             const response = await fetch(`${baseUrl}SearchAbha`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             const data = await response.json();
//             console.log(data.status)
//             if (data && data.status === "ACTIVE") {
//                 // toast.success("Card details Verified");
//                 setAbhaNumber('');
//                 // Show SweetAlert popup
//                 Swal.fire({
//                     title: 'Success!',
//                     html: `Card details Verified<br>Name: ${data.fullName}<br>Abha Number: ${data.healthIdNumber}`,
//                     icon: 'success',
//                     confirmButtonText: 'OK',
//                     customClass: {
//                         confirmButton: 'swal-confirm-button',
//                     },
//                 });


//             } else {
//                 toast.error(data.message);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             toast.error('An error occurred. Please try again.');
//         }
//     };
//     const publicKeyPem = `-----BEGIN PUBLIC KEY-----
//     MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
//     4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
//     wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
//     68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
//     8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
//     Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
//     rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
//     ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
//     0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
//     iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
//     o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
//     IzQpnSVDUVEzv17grVAw078CAwEAAQ==
//     -----END PUBLIC KEY-----`;

//     const handleAadharChange = async (event) => {
//         event.preventDefault(); // Prevent default form submission
//         if (Aadhaar) {
//             try {
//                 // Encrypt Aadhar number
//                 const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(Aadhaar, 'RSA-OAEP');
//                 const encryptedAadharNo = forge.util.encode64(encryptedData);

//                 const formData = new FormData();
//                 formData.append('loginId', encryptedAadharNo);

//                 const response = await axios.post(`${baseUrl}request-otp-AbhaByAadharNumber`, formData, {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 });

//                 console.log("response", response);
//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     setTxnId(true);
//                 } else {
//                     toast.error(response.error.message || 'Failed to generate OTP');
//                 }
//             } catch (error) {
//                 console.error('Error fetching ABHA details:', error);
//                 toast.error('An error occurred while fetching ABHA details');
//             }
//         } else {
//             toast.error('Invalid Abha address or consent not checked.');
//         }
//     };

//     const handleAadharMobileChange = async (event) => {
//         event.preventDefault(); // Prevent default form submission
//         if (otp) {
//             try {
//                 // Encrypt Aadhar number
//                 const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
//                 const encryptedOTP = forge.util.encode64(encryptedData);

//                 // const formData = new FormData();
//                 // formData.append('otpValue', encryptedOTP);
//                 const body = {
//                     "otpValue": encryptedOTP,
//                     "mobile": mobileNo
//                 }
//                 const response = await axios.post(`${baseUrl}verify-by-AbhaByAadharNumber`, body, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });

//                 console.log("response", response);
//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     if (response.data.message === 'OTP verified successfully') {
//                         toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`)
//                     }
//                 } else {
//                     toast.error(response.error.message || 'Failed to generate OTP');
//                 }
//             } catch (error) {
//                 console.error('Error fetching ABHA details:', error);
//                 toast.error('An error occurred while fetching ABHA details Please try again in some time.');
//             }
//         }

//     };

//     return (
//         <Layout>
//             <Container maxWidth="lg" >
//                 <div>
//                     <Typography variant="h6" className={classes.formTitle}>
//                         ABHA Verification by OTP (Registered Mobile Number on Aadhar)
//                     </Typography>
//                     {!txnId ?
//                         <>
//                             <form onSubmit={handleAadharChange}>
//                                 <Grid container>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             label="Aadhar Number"
//                                             name="firstName"
//                                             value={Aadhaar}
//                                             onChange={(event) => {
//                                                 setAadhaar(event.target.value);
//                                             }}
//                                         />
//                                     </Grid>
//                                 </Grid>
//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     className={classes.submitButton}
//                                 >
//                                     Submit
//                                 </Button>
//                             </form>
//                         </> : ''}
//                     {txnId ?
//                         <>
//                             <form onSubmit={handleAadharMobileChange}>
//                                 <Grid container>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             label="OTP"
//                                             name="otp"
//                                             value={otp}
//                                             onChange={(event) => {
//                                                 setotp(event.target.value);
//                                             }}
//                                         />
//                                     </Grid>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             label="Mobile Number"
//                                             required
//                                             value={mobileNo}
//                                             inputProps={{ maxLength: 10, minlength: 10 }}
//                                             onChange={(event) => {
//                                                 const value = event.target.value;
//                                                 if (/^\d*$/.test(value)) {
//                                                     setMobileNo(value);
//                                                 }
//                                             }}
//                                         />
//                                     </Grid>
//                                 </Grid>

//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     className={classes.submitButton}
//                                 >
//                                     Submit
//                                 </Button>
//                             </form>
//                         </>
//                         : ''
//                     }
//                 </div>

//             </Container>
//         </Layout>
//     )
// }

// export default CreateAbha





// import React, { useState, useRef } from 'react';
// import Layout from '../Layout/Layout';
// import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { baseUrl } from '../Redux/Slice/Aadhar';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import forge from 'node-forge';

// const useStyles = makeStyles((theme) => ({
//     formContainer: {
//         padding: theme.spacing(4),
//         backgroundColor: '#f5f5f5',
//         borderRadius: '8px',
//     },
//     submitButton: {
//         marginTop: theme.spacing(4),
//         backgroundColor: '#007bff',
//         color: '#fff',
//         '&:hover': {
//             backgroundColor: '#0056b3',
//         },
//     },
//     formTitle: {
//         marginBottom: theme.spacing(2),
//         color: '#007bff',
//     },
// }));

// const CreateAbha = () => {
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const [abhaSegments, setAbhaSegments] = useState({
//         prefix: '',
//         part1: '',
//         part2: '',
//         part3: ''
//     });
//     const [otp, setOtp] = useState('');
//     const [txnId, setTxnId] = useState(false);
//     const [mobileNo, setMobileNo] = useState('');
//     const part1Ref = useRef(null);
//     const part2Ref = useRef(null);
//     const part3Ref = useRef(null);

//     const publicKeyPem = `-----BEGIN PUBLIC KEY-----
//     MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
//     4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
//     wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
//     68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
//     8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
//     Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
//     rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
//     ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
//     0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
//     iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
//     o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
//     IzQpnSVDUVEzv17grVAw078CAwEAAQ==
//     -----END PUBLIC KEY-----`;

//     const handleAbhaChange = (event) => {
//         const { name, value } = event.target;
//         setAbhaSegments((prev) => ({
//             ...prev,
//             [name]: value,
//         }));

//         // Move to the next field based on input length
//         if (name === 'prefix' && value.length === 2) {
//             part1Ref.current.focus();
//         } else if (name === 'part1' && value.length === 4) {
//             part2Ref.current.focus();
//         } else if (name === 'part2' && value.length === 4) {
//             part3Ref.current.focus();
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const { prefix, part1, part2, part3 } = abhaSegments;
//         const abhaNumber = `${prefix}-${part1}-${part2}-${part3}`;

//         try {
//             const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(abhaNumber, 'RSA-OAEP');
//             const encryptedAbhaNumber = forge.util.encode64(encryptedData);

//             const formData = new FormData();
//             formData.append('loginId', encryptedAbhaNumber);

//             const response = await fetch(`${baseUrl}request-otp-AbhaByAadharNumber`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             const data = await response.json();
//             if (data && data.message) {
//                 toast.success(data.message);
//                 setTxnId(true);
//             } else {
//                 toast.error(data.message);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             toast.error('An error occurred. Please try again.');
//         }
//     };

//     const handleAadharMobileChange = async (event) => {
//         event.preventDefault();
//         if (otp && mobileNo) {
//             try {
//                 const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
//                 const encryptedOTP = forge.util.encode64(encryptedOtp);

//                 const body = {
//                     "otpValue": encryptedOTP,
//                     "mobile": mobileNo
//                 };

//                 const response = await axios.post(`${baseUrl}verify-by-AbhaByAadharNumber`, body, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });

//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     if (response.data.message === 'OTP verified successfully') {
//                         // Show SweetAlert popup
//                         Swal.fire({
//                             title: 'Success!',
//                             html: `Card details Verified<br>Name: ${response.data.accounts[0].name}<br>Abha Number: ${response.data.accounts[0].abhaNumber}<br>Abha Address: ${response.data.accounts[0].preferredAbhaAddress}<br>Status: ${response.data.accounts[0].status}`,
//                             icon: 'success',
//                             confirmButtonText: 'OK',
//                             customClass: {
//                                 confirmButton: 'swal-confirm-button',
//                             },
//                         });
//                     }
//                 } else {
//                     toast.error(response.error.message || 'Failed to verify OTP');
//                 }
//             } catch (error) {
//                 console.error('Error verifying OTP:', error);
//                 toast.error('An error occurred while verifying OTP. Please try again.');
//             }
//         } else {
//             toast.error('Please enter both OTP and mobile number.');
//         }
//     };

//     return (
//         <Layout>
//             <Container maxWidth="lg">
//                 <div>
//                     <Typography variant="h6" className={classes.formTitle}>
//                         ABHA Verification by OTP (Registered Mobile Number on Aadhar)
//                     </Typography>
//                     {!txnId ? (
//                         <form onSubmit={handleSubmit}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={12} sm={2}>
//                                     <TextField
//                                         variant="outlined"
//                                         label="ABHA Number"
//                                         name="prefix"
//                                         value={abhaSegments.prefix}
//                                         onChange={handleAbhaChange}
//                                         inputProps={{ maxLength: 2 }}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={2}>
//                                     <TextField
//                                         variant="outlined"
//                                         // label="Part 1"
//                                         name="part1"
//                                         value={abhaSegments.part1}
//                                         onChange={handleAbhaChange}
//                                         inputRef={part1Ref}
//                                         inputProps={{ maxLength: 4 }}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={2}>
//                                     <TextField
//                                         variant="outlined"
//                                         // label="Part 2"
//                                         name="part2"
//                                         value={abhaSegments.part2}
//                                         onChange={handleAbhaChange}
//                                         inputRef={part2Ref}
//                                         inputProps={{ maxLength: 4 }}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={2}>
//                                     <TextField
//                                         variant="outlined"
//                                         // label="Part 3"
//                                         name="part3"
//                                         value={abhaSegments.part3}
//                                         onChange={handleAbhaChange}
//                                         inputRef={part3Ref}
//                                         inputProps={{ maxLength: 4 }}
//                                     />
//                                 </Grid>
//                             </Grid>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 className={classes.submitButton}
//                             >
//                                 Submit
//                             </Button>
//                         </form>
//                     ) : (
//                         <form onSubmit={handleAadharMobileChange}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={12} sm={4}>
//                                     <TextField
//                                         variant="outlined"
//                                         fullWidth
//                                         label="OTP"
//                                         value={otp}
//                                         onChange={(event) => setOtp(event.target.value)}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={4}>
//                                     <TextField
//                                         variant="outlined"
//                                         fullWidth
//                                         label="Mobile Number"
//                                         value={mobileNo}
//                                         onChange={(event) => setMobileNo(event.target.value)}
//                                         inputProps={{ maxLength: 10 }}
//                                     />
//                                 </Grid>
//                             </Grid>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 className={classes.submitButton}
//                             >
//                                 Verify OTP
//                             </Button>
//                         </form>
//                     )}
//                 </div>
//             </Container>
//         </Layout>
//     );
// };

// export default CreateAbha;



// import React, { useRef, useState } from 'react'
// import Layout from '../Layout/Layout';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import forge from 'node-forge'
// import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
// import { baseUrl } from '../Redux/Slice/Aadhar';
// import { toast } from 'react-toastify';
// import Swal from 'sweetalert2';


// const CreateAbha = () => {
//     const dispatch = useDispatch();
//     const [abhaSegments, setAbhaSegments] = useState({
//         prefix: '',
//         part1: '',
//         part2: '',
//         part3: ''
//     });
//     const [otp, setOtp] = useState('');
//     const [txnId, setTxnId] = useState(false);
//     const [mobileNo, setMobileNo] = useState('');
//     const part1Ref = useRef(null);
//     const part2Ref = useRef(null);
//     const part3Ref = useRef(null);

//     const publicKeyPem = `-----BEGIN PUBLIC KEY-----
//         MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
//         4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
//         wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
//         68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
//         8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
//         Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
//         rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
//         ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
//         0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
//         iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
//         o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
//         IzQpnSVDUVEzv17grVAw078CAwEAAQ==
//         -----END PUBLIC KEY-----`;

//     const handleAbhaChange = (event) => {
//         const { name, value } = event.target;
//         setAbhaSegments((prev) => ({
//             ...prev,
//             [name]: value,
//         }));

//         // Move to the next field based on input length
//         if (name === 'prefix' && value.length === 2) {
//             part1Ref.current.focus();
//         } else if (name === 'part1' && value.length === 4) {
//             part2Ref.current.focus();
//         } else if (name === 'part2' && value.length === 4) {
//             part3Ref.current.focus();
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const { prefix, part1, part2, part3 } = abhaSegments;
//         const abhaNumber = `${prefix}-${part1}-${part2}-${part3}`;

//         try {
//             const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(abhaNumber, 'RSA-OAEP');
//             const encryptedAbhaNumber = forge.util.encode64(encryptedData);

//             const formData = new FormData();
//             formData.append('loginId', encryptedAbhaNumber);

//             const response = await fetch(`${baseUrl}request-otp-AbhaByAadharNumber`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             const data = await response.json();
//             if (data && data.message) {
//                 toast.success(data.message);
//                 setTxnId(true);
//             } else {
//                 toast.error(data.message);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             toast.error('An error occurred. Please try again.');
//         }
//     };

//     const handleAadharMobileChange = async (event) => {
//         event.preventDefault();
//         if (otp && mobileNo) {
//             try {
//                 const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
//                 const encryptedOTP = forge.util.encode64(encryptedOtp);

//                 const body = {
//                     "otpValue": encryptedOTP,
//                     "mobile": mobileNo
//                 };

//                 const response = await axios.post(`${baseUrl}verify-by-AbhaByAadharNumber`, body, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });

//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     if (response.data.message === 'OTP verified successfully') {
//                         // Show SweetAlert popup
//                         Swal.fire({
//                             title: 'Success!',
//                             html: `Card details Verified<br>Name: ${response.data.accounts[0].name}<br>Abha Number: ${response.data.accounts[0].abhaNumber}<br>Abha Address: ${response.data.accounts[0].preferredAbhaAddress}<br>Status: ${response.data.accounts[0].status}`,
//                             icon: 'success',
//                             confirmButtonText: 'OK',
//                             customClass: {
//                                 confirmButton: 'swal-confirm-button',
//                             },
//                         });
//                     }
//                 } else {
//                     toast.error(response.error.message || 'Failed to verify OTP');
//                 }
//             } catch (error) {
//                 console.error('Error verifying OTP:', error);
//                 toast.error('An error occurred while verifying OTP. Please try again.');
//             }
//         } else {
//             toast.error('Please enter both OTP and mobile number.');
//         }
//     };
//     return (
//         <>
//             <Layout />
//             <div>
//                 {!txnId ? (
//                     <form onSubmit={handleSubmit}>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} sm={2}>
//                                 <TextField
//                                     variant="outlined"
//                                     label="ABHA Number"
//                                     name="prefix"
//                                     value={abhaSegments.prefix}
//                                     onChange={handleAbhaChange}
//                                     inputProps={{ maxLength: 2 }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={2}>
//                                 <TextField
//                                     variant="outlined"
//                                     // label="Part 1"
//                                     name="part1"
//                                     value={abhaSegments.part1}
//                                     onChange={handleAbhaChange}
//                                     inputRef={part1Ref}
//                                     inputProps={{ maxLength: 4 }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={2}>
//                                 <TextField
//                                     variant="outlined"
//                                     // label="Part 2"
//                                     name="part2"
//                                     value={abhaSegments.part2}
//                                     onChange={handleAbhaChange}
//                                     inputRef={part2Ref}
//                                     inputProps={{ maxLength: 4 }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={2}>
//                                 <TextField
//                                     variant="outlined"
//                                     // label="Part 3"
//                                     name="part3"
//                                     value={abhaSegments.part3}
//                                     onChange={handleAbhaChange}
//                                     inputRef={part3Ref}
//                                     inputProps={{ maxLength: 4 }}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Button
//                             type="submit"
//                             variant="contained"
//                         // className={classes.submitButton}
//                         >
//                             Submit
//                         </Button>
//                     </form>
//                 ) : (
//                     <form onSubmit={handleAadharMobileChange}>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} sm={4}>
//                                 <TextField
//                                     variant="outlined"
//                                     fullWidth
//                                     label="OTP"
//                                     value={otp}
//                                     onChange={(event) => setOtp(event.target.value)}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={4}>
//                                 <TextField
//                                     variant="outlined"
//                                     fullWidth
//                                     label="Mobile Number"
//                                     value={mobileNo}
//                                     onChange={(event) => setMobileNo(event.target.value)}
//                                     inputProps={{ maxLength: 10 }}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Button
//                             type="submit"
//                             variant="contained"
//                         // className={classes.submitButton}
//                         >
//                             Verify OTP
//                         </Button>
//                     </form>
//                 )}
//             </div>


//         </>
//     )
// }

// export default CreateAbha



import React, { useRef, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch } from 'react-redux';
import axios from 'axios';
import forge from 'node-forge'
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { baseUrl } from '../Redux/Slice/Aadhar';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


const CreateAbha = () => {
    const dispatch = useDispatch();
    const [abhaSegments, setAbhaSegments] = useState({
        prefix: '',
        part1: '',
        part2: '',
        part3: ''
    });
    const [otp, setOtp] = useState('');
    const [txnId, setTxnId] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const part1Ref = useRef(null);
    const part2Ref = useRef(null);
    const part3Ref = useRef(null);
    const reset = () => {
        setAbhaSegments({
            prefix: '',
            part1: '',
            part2: '',
            part3: ''
        });
        setOtp('');
        setTxnId(false);
        setMobileNo('');
    };

    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
            MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
            4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
            wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
            68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
            8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
            Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
            rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
            ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
            0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
            iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
            o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
            IzQpnSVDUVEzv17grVAw078CAwEAAQ==
            -----END PUBLIC KEY-----`;
    // Handle changes for ABHA segments
    const handleAbhaChange = (event) => {
        const { name, value } = event.target;
        setAbhaSegments((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Move to the next field based on input length
        if (name === 'prefix' && value.length === 2) {
            if (part1Ref.current) part1Ref.current.focus(); // Safe check
        } else if (name === 'part1' && value.length === 4) {
            if (part2Ref.current) part2Ref.current.focus(); // Safe check
        } else if (name === 'part2' && value.length === 4) {
            if (part3Ref.current) part3Ref.current.focus(); // Safe check
        }
    };

    // const handleAbhaChange = (event) => {
    //     const { name, value } = event.target;
    //     setAbhaSegments((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));

    //     // Move to the next field based on input length
    //     if (name === 'prefix' && value.length === 2) {
    //         part1Ref.current.focus();
    //     } else if (name === 'part1' && value.length === 4) {
    //         part2Ref.current.focus();
    //     } else if (name === 'part2' && value.length === 4) {
    //         part3Ref.current.focus();
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { prefix, part1, part2, part3 } = abhaSegments;
        const abhaNumber = `${prefix}-${part1}-${part2}-${part3}`;

        try {
            const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(abhaNumber, 'RSA-OAEP');
            const encryptedAbhaNumber = forge.util.encode64(encryptedData);

            const formData = new FormData();
            formData.append('loginId', encryptedAbhaNumber);

            const response = await fetch(`${baseUrl}request-otp-AbhaByAadharNumber`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data && data.message) {
                toast.success(data.message);
                setTxnId(true);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleAadharMobileChange = async (event) => {
        event.preventDefault();
        if (otp && mobileNo) {
            try {
                const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
                const encryptedOTP = forge.util.encode64(encryptedOtp);

                const body = {
                    "otpValue": encryptedOTP,
                    "mobile": mobileNo
                };

                const response = await axios.post(`${baseUrl}verify-by-AbhaByAadharNumber`, body, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.data.message) {
                    reset();
                    toast.success(response.data.message);
                    if (response.data.message === 'OTP verified successfully') {
                        // Show SweetAlert popup
                        Swal.fire({
                            title: 'Success!',
                            html: `Card details Verified<br>Name: ${response.data.accounts[0].name}<br>Abha Number: ${response.data.accounts[0].abhaNumber}<br>Abha Address: ${response.data.accounts[0].preferredAbhaAddress}<br>Status: ${response.data.accounts[0].status}`,
                            icon: 'success',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'swal-confirm-button',
                            },
                        });
                    }
                } else {
                    toast.error(response.error.message || 'Failed to verify OTP');
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                toast.error('An error occurred while verifying OTP. Please try again.');
            }
        } else {
            toast.error('Please enter both OTP and mobile number.');
        }
    };

    return (
        <>
            <Layout />


            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">ABHA Verification</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">by Aadhar OTP</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>ABHA Verification by OTP (Registered Mobile Number on Aadhar)</h4>
                                                    <div>
                                                        {!txnId ? (
                                                            <form onSubmit={handleSubmit}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={2}>
                                                                        <label >ABHA Number <span className="login-danger">*</span></label>

                                                                        <input
                                                                            name="prefix"
                                                                            className="form-control"
                                                                            value={abhaSegments.prefix}
                                                                            onChange={handleAbhaChange}
                                                                            maxLength={2}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={2}>
                                                                        <label > </label>

                                                                        <input

                                                                            className="form-control"
                                                                            name="part1"
                                                                            value={abhaSegments.part1}
                                                                            onChange={handleAbhaChange}
                                                                            inputRef={part1Ref}
                                                                            maxLength={4}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={2}>
                                                                        <label > </label>

                                                                        <input
                                                                            variant="outlined"
                                                                            // label="Part 2"
                                                                            className="form-control"
                                                                            name="part2"
                                                                            value={abhaSegments.part2}
                                                                            onChange={handleAbhaChange}
                                                                            inputRef={part2Ref}
                                                                            maxLength={4}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={2}>
                                                                        <label ></label>

                                                                        <input
                                                                            variant="outlined"
                                                                            // label="Part 3"
                                                                            className="form-control"
                                                                            name="part3"
                                                                            value={abhaSegments.part3}
                                                                            onChange={handleAbhaChange}
                                                                            inputRef={part3Ref}
                                                                            maxLength={4}
                                                                        />
                                                                    </Grid>
                                                                </Grid>



                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleSubmit}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form" onClick={reset}>Cancel</button>
                                                                </div>
                                                                {/* <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                // className={classes.submitButton}
                                                                >
                                                                    Submit
                                                                </Button> */}
                                                            </form>
                                                        ) : (
                                                            <form onSubmit={handleAadharMobileChange}>
                                                                <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={4}>
                                                                        <label >Mobile No <span className="login-danger">*</span></label>

                                                                        <input
                                                                            maxLength={10}
                                                                            minLength={10}
                                                                            className="form-control"
                                                                            label="Mobile Number"
                                                                            value={mobileNo}
                                                                            onChange={(event) => setMobileNo(event.target.value)}

                                                                        />
                                                                    </Grid>
                      
                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >OTP<span className="login-danger">*</span></label>

                                                                        <input
                                                                            className="form-control"
                                                                            maxLength={6}
                                                                            label="OTP"
                                                                            value={otp}
                                                                            onChange={(event) => setOtp(event.target.value)}
                                                                        />
                                                                    </Grid>
                                                                                                           </Grid>
                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleAadharMobileChange}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form" onClick={reset}>Cancel</button>
                                                                </div>
                                                                {/* <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                // className={classes.submitButton}
                                                                >
                                                                    Verify OTP
                                                                </Button> */}
                                                            </form>
                                                        )}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}

export default CreateAbha