// import React from 'react';
// import { Grid, Typography, Card, CardContent, Container, CssBaseline } from '@mui/material';
// // import Header from '../Layout/Header';
// import Footer from '../Layout/Footer';
// import Layout from '../Layout/Layout';


// const Home = () => {
//     return (
//         <>
//             <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>


//                 <Layout />

//                 {/* <Footer /> */}
//             </div>
//         </>
//     );
// };

// export default Home;



import React from 'react';
import Layout from '../Layout/Layout'; // Adjust import path as per your project structure
import { Typography, Grid, Card, CardContent, CardActions, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 216,
    },
    cardContent: {
        flexGrow: 1,
    },
    cardActions: {
        justifyContent: 'flex-end',
    },
}));
const HomePage = () => {
    const classes = useStyles();
    // Sample data for charts
    const chartData = {
        labels: ["2016", "2017", "2018", "2019", "2020"],
        datasets: [
          {
            label: "Users Gained",
            data: [100, 220, 150, 180, 200],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: true,
          },
          {
            label: "Users Lost",
            data: [120, 80, 40, 150, 60],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: true,
          },
        ],
      };
    return (
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h6" gutterBottom>
                                Total Patients
                            </Typography>
                            <Typography variant="h4">
                                1200
                            </Typography>
                            <Typography variant="body2">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies arcu eu mi facilisis, id aliquam nisl pulvinar.
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Button size="small" color="primary">
                                View Details
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h6" gutterBottom>
                                Average Revenue
                            </Typography>
                            <Typography variant="h4">
                                $2500
                            </Typography>
                            <Typography variant="body2">
                                Curabitur ac lacus nec nisi fermentum tempor.
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Button size="small" color="primary">
                                View Details
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h6" gutterBottom>
                                Pending Appointments
                            </Typography>
                            <Typography variant="h4">
                                25
                            </Typography>
                            <Typography variant="body2">
                                Nullam ac felis eget urna viverra rhoncus in eget velit.
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Button size="small" color="primary">
                                View Details
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Department Distribution
                            </Typography>
                            <div style={{ height: 300 }}>
                                {/* <Pie data={pieChartData} /> */}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Monthly Revenue
                            </Typography>
                            <div style={{ height: 300 }}>
                            {/* <BarChart chartData={chartData} /> */}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Patients Trend
                            </Typography>
                            <div style={{ height: 300 }}>
                                {/* <Line data={lineChartData} /> */}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default HomePage;
