import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./Aadhar";


// Initial state for the slice
const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

// API URL
const apiUrl = `${baseUrl}EnrollPatient`; // Replace with your API endpoint URL

// Custom headers for the API request
const customHeader = {
  "Content-Type": "application/json",
  Accept: "text/plain",
};

// Async thunk to book posts
export const BookPosts = createAsyncThunk("posts/BookPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming response contains data directly
  } catch (error) {
    // Handle errors
    console.error("Error booking posts:", error);
    throw error;
  }
});

// Slice to manage posts state
const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(BookPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(BookPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(BookPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default postsSlice.reducer;
