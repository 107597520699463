import React, { useState } from 'react';
import Layout from '../Layout/Layout';
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../Redux/Slice/Aadhar';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const VerifyAbha = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [AbhaNumber, setAbhaNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate ABHA address
        if (!AbhaNumber.endsWith('@sbx')) {
            toast.error('ABHA address must end with @sbx.');
            return;
        }

        const formData = new FormData();
        formData.append('AbhaAddress', AbhaNumber);

        try {
            const response = await fetch(`${baseUrl}SearchAbha`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            console.log(data.status);
            if (data && data.status === "ACTIVE") {
                setAbhaNumber('');
                // Show SweetAlert popup
                Swal.fire({
                    title: 'Success!',
                    html: `Card details Verified<br>Name: ${data.fullName}<br>Mobile: ${data.mobile}<br>Abha Address: ${data.abhaAddress}<br>Status: ${data.status}<br>Abha Number: ${data.healthIdNumber}`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'swal-confirm-button',
                    },
                });
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Layout />

            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">ABHA Verification</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">By ABHA Address</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>ABHA Verification</h4>
                                                    <div>

                                                        <form onSubmit={handleSubmit}>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >ABHA Address <span className="login-danger">*</span></label>

                                                                <input

                                                                    fullWidth
                                                                    className="form-control"

                                                                    name="abhaAddress" // Change to a more appropriate name
                                                                    value={AbhaNumber}
                                                                    onChange={(event) => {
                                                                        setAbhaNumber(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <br />

                                                            <Typography >
                                                                Make sure your ABHA address is formatted properly as @sbx for seamless processing.
                                                            </Typography>
                                                            <div class="doctor-submit text-end">
                                                                <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleSubmit}>Submit</button>
                                                                <button type="cancel" className="btn btn-primary cancel-form" >Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default VerifyAbha;
