// import React, { useEffect, useState } from 'react';
// import { Container, TextField, Button, Grid, Typography, Paper, MenuItem, Divider, Box, FormControlLabel, Checkbox, DialogActions, Dialog, DialogTitle, DialogContent, Select, InputLabel, FormControl } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import Layout from '../Layout/Layout';
// import { GETAPI, POSTAPI } from '../Redux/Slice/Address';
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
// import { BookPosts } from '../Redux/Slice/Registration';
// import forge from 'node-forge'
// import { AadharPosts, baseUrl } from '../Redux/Slice/Aadhar';
// import axios from 'axios';
// const useStyles = makeStyles((theme) => ({
//     formContainer: {
//         padding: theme.spacing(4),
//         backgroundColor: '#f5f5f5',
//         borderRadius: '8px',
//     },
//     formField: {
//         margin: theme.spacing(2, 0),
//     },
//     submitButton: {
//         marginTop: theme.spacing(4),
//         backgroundColor: '#007bff',
//         color: '#fff',
//         '&:hover': {
//             backgroundColor: '#0056b3',
//         },
//     },
//     formTitle: {
//         marginBottom: theme.spacing(2),
//         color: '#007bff',
//     },
// }));

// const Form = () => {
//     const classes = useStyles();
//     // form state
//     const [firstName, setFirstName] = useState('');
//     const [middleName, setMiddleName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [prefix, setPrefix] = useState('');
//     const [unit, setUnit] = useState('');
//     const [prefixData, setPrefixData] = useState([]);
//     const [gender, setGender] = useState('');
//     const [mobileNo, setMobileNo] = useState('');
//     const [EditmobileNo, setEditMobileNo] = useState('');
//     const [contactNo, setContactNo] = useState('');
//     const [email, setEmail] = useState('');
//     const [dob, setDob] = useState('');
//     const [resAddress, setResAddress] = useState('');
//     const [resCountry, setResCountry] = useState('');
//     const [resState, setResState] = useState('');
//     const [resDistrict, setResDistrict] = useState('');
//     const [resTaluka, setResTaluka] = useState('');
//     const [resTown, setResTown] = useState('');
//     const [resAreaCode, setResAreaCode] = useState('');
//     const [perAddress, setPerAddress] = useState('');
//     const [perCountry, setPerCountry] = useState('');
//     const [perState, setPerState] = useState('');
//     const [perDistrict, setPerDistrict] = useState('');
//     const [perTaluka, setPerTaluka] = useState('');
//     const [perTown, setPerTown] = useState('');
//     const [perAreaCode, setPerAreaCode] = useState('');
//     const [aadharNo, setAadharNo] = useState('');
//     const [aadhar, setAadhar] = useState('');
//     const [abhaId, setAbhaId] = useState('');
//     const [abhaAddress, setAbhaAddress] = useState('');
//     const [sameAddress, setSameAddress] = useState(false);
//     const [AbhaAddress1Data, setAbhaAddress1Data] = useState([]);
//     const [AbhaAddress1, setAbhaAddress1] = useState('');
//     const [isMobileVerified, setIsMobileVerified] = useState(false); // New state variable

//     const [errors, setErrors] = useState({});
//     const handleCheckboxChange = (e) => {
//         setSameAddress(e.target.checked);
//         if (e.target.checked) {
//             setResAddress(perAddress);
//             setResCountry(perCountry);
//             setResState(perState);
//             setResDistrict(perDistrict);
//             setResTaluka(perTaluka);
//             setResTown(perTown);
//             setResAreaCode(perAreaCode);
//         } else {
//             // Reset residential address fields if unchecked
//             setResAddress('');
//             setResCountry('');
//             setResState('');
//             setResDistrict('');
//             setResTaluka('');
//             setResTown('');
//             setResAreaCode('');
//         }
//     };
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const resetForm = () => {
//         setFirstName('');
//         setUnit('');
//         setPrefix('');
//         setMiddleName('');
//         setLastName('');
//         setGender('');
//         setMobileNo('');
//         setContactNo('');
//         setEmail('');
//         setDob('');
//         setResAddress('');
//         setResCountry('');
//         setResState('');
//         setResDistrict('');
//         setResTaluka('');
//         setResTown('');
//         setResAreaCode('');
//         setPerAddress('');
//         setPerCountry('');
//         setPerState('');
//         setPerDistrict('');
//         setPerTaluka('');
//         setPerTown('');
//         setPerAreaCode('');
//         setAadharNo('');
//         setAbhaId('');
//         setAbhaAddress('');
//         setSameAddress(false);
//     };
//     const appendFormData = (formData, key, value) => {
//         formData.append(key, value ? value : '0');
//     };
//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const formData = new FormData();
//         formData.append('Id', '0');
//         formData.append('Unit', unit);
//         formData.append('Prefix', prefix);
//         formData.append('FirstName', firstName);
//         formData.append('LastName', lastName);
//         formData.append('Gender', gender);
//         formData.append('MobileNo', mobileNo);
//         formData.append('Email', email);
//         formData.append('Dob', dob);
//         formData.append('ResAddress', resAddress);
//         formData.append('MiddleName', middleName ? middleName : '');
//         // appendFormData(formData, 'MiddleName', middleName);
//         formData.append('PerAddress', perAddress);
//         appendFormData(formData, 'ResCountry', resCountry);
//         appendFormData(formData, 'ContactNo', contactNo);
//         appendFormData(formData, 'ResState', resState);
//         appendFormData(formData, 'ResDistrict', resDistrict);
//         appendFormData(formData, 'ResTaluka', resTaluka);
//         appendFormData(formData, 'ResTown', resTown);
//         appendFormData(formData, 'ResAreaCode', resAreaCode);
//         appendFormData(formData, 'PerCountry', perCountry);
//         appendFormData(formData, 'PesState', perState);
//         appendFormData(formData, 'PerDistrict', perDistrict);
//         appendFormData(formData, 'PerTaluka', perTaluka);
//         appendFormData(formData, 'PerTown', perTown);
//         formData.append('PerAreaCode', perAreaCode);
//         formData.append('AadharNo', aadharNo);
//         formData.append('AbhaId', abhaId);
//         formData.append('AbhaAddress', abhaAddress);
//         const response = await dispatch(BookPosts(formData));
//         console.log(response.payload.success)

//         if (response.payload.success === true && response.payload.message === "Patient added successfully.") {
//             toast.success("Patient Added successfully");
//             resetForm();
//         }
//         else {
//             toast.error(response.payload.message);
//         }

//     };
//     // state,city,district
//     const [Country, setCountry] = useState([]);
//     const [State, setState] = useState([]);
//     const [District, setDistrict] = useState([]);
//     const [Taluka, setTaluka] = useState([]);
//     const [Town, setTown] = useState([]);
//     // Permanent
//     const getState = async (id) => {
//         setPerCountry(id);
//         try {
//             const result1 = await POSTAPI("State", id);
//             if (result1) {
//                 setState(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getDistrict = async (id) => {
//         setPerState(id);
//         try {
//             const result1 = await POSTAPI("District", id);
//             if (result1) {
//                 setDistrict(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getTaluka = async (id) => {
//         setPerDistrict(id);
//         try {
//             const result1 = await POSTAPI("Taluka", id);
//             // if (result1) {
//             setTaluka(result1);
//             console.log(result1)
//             // }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getTown = async (id) => {
//         setPerTaluka(id)
//         try {
//             const result1 = await POSTAPI("Town", id);
//             if (result1) {
//                 setTown(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     // Residential
//     const getRState = async (id) => {
//         setResCountry(id);
//         try {
//             const result1 = await POSTAPI("State", id);
//             if (result1.length > 0) {
//                 setState(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getRDistrict = async (id) => {
//         setResState(id);
//         try {
//             const result1 = await POSTAPI("District", id);
//             if (result1) {
//                 setDistrict(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getRTaluka = async (id) => {
//         setResDistrict(id);
//         try {
//             const result1 = await POSTAPI("Taluka", id);
//             if (result1) {
//                 setTaluka(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getRTown = async (id) => {
//         setResTaluka(id)
//         try {
//             const result1 = await POSTAPI("Town", id);
//             if (result1) {
//                 setTown(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
//     const getView = async () => {
//         try {
//             const result = await GETAPI("Country");
//             if (result) {
//                 setCountry(result);
//             }
//             const result1 = await GETAPI("Prefix");
//             if (result1) {
//                 setPrefixData(result1);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };

//     useEffect(() => {

//         getView();
//     }, []);

//     // ABHA
//     const [open, setOpen] = useState(false);
//     const [AbhaDetails, setAbhaDetails] = useState('');

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//         setTxnId(false);
//         setAadharNo('');
//         setConsentChecked(false);
//         setOTP('');
//         setMobile('');
//     };
//     const publicKeyPem = `-----BEGIN PUBLIC KEY-----
//     MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
//     4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
//     wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
//     68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
//     8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
//     Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
//     rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
//     ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
//     0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
//     iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
//     o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
//     IzQpnSVDUVEzv17grVAw078CAwEAAQ==
//     -----END PUBLIC KEY-----`;


//     const [txnId, setTxnId] = useState(false); // State for transaction ID

//     const formatDate = (dateString) => {
//         // Assuming dateString is in "DD-MM-YYYY" format
//         const parts = dateString.split('-');
//         if (parts.length === 3) {
//             return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to "YYYY-MM-DD" format
//         }
//         return '';
//     };
//     const handleAadharChange = async (event) => {
//         event.preventDefault(); // Prevent default form submission
//         setAadhar(aadharNo)
//         if (aadharNo.length === 12 && consentChecked) {
//             try {
//                 // Encrypt Aadhar number
//                 const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(aadharNo, 'RSA-OAEP');
//                 const encryptedAadharNo = forge.util.encode64(encryptedData);

//                 const formData = new FormData();
//                 formData.append('loginId', encryptedAadharNo);

//                 const response = await axios.post(`${baseUrl}request-otp-aadhar`, formData, {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 });

//                 console.log("response", response);
//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     setTxnId(true);


//                 } else {
//                     toast.error(response.error.message || 'Failed to generate OTP');
//                 }
//             } catch (error) {
//                 console.error('Error fetching ABHA details:', error);
//                 toast.error('An error occurred while fetching ABHA details');
//             }
//         } else {
//             toast.error('Invalid Aadhar number or consent not checked.');
//         }
//     };

//     const handleMobileChange = async (event) => {
//         event.preventDefault(); // Prevent default form submission
//         if (MobileNo) { setEditMobileNo(MobileNo) }

//         try {
//             // Encrypt Aadhar number
//             const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(OTP, 'RSA-OAEP');
//             const encryptedOTP = forge.util.encode64(encryptedData);

//             // const formData = new FormData();
//             // formData.append('otpValue', encryptedOTP);
//             const body = {
//                 "otpValue": encryptedOTP,
//                 "mobile": MobileNo
//             }
//             const response = await axios.post(`${baseUrl}verify-by-aadhaar`, body, {
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             });

//             console.log("response", response);
//             if (response.data.message) {
//                 setIsMobileVerified(true);
//                 toast.success(response.data.message);
//                 if (response.data.message === 'This account already exist') {
//                     toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`)
//                     setAbhaDetails(response.data.abhaProfile);
//                     setFirstName(response.data.abhaProfile.firstName);
//                     setMiddleName(response.data.abhaProfile.middleName);
//                     setLastName(response.data.abhaProfile.lastName);
//                     setAbhaId(response.data.abhaProfile.abhaNumber);
//                     setResAddress(response.data.abhaProfile.address);
//                     setPerAddress(response.data.abhaProfile.address);
//                     setDob(formatDate(response.data.abhaProfile.dob));
//                     setGender(response.data.abhaProfile.gender);
//                     setAadharNo(aadhar);
//                     setMobileNo(response.data.abhaProfile.mobile !== null ? response.data.abhaProfile.mobile
//                         : EditmobileNo
//                     );
//                     setEmail(response.data.abhaProfile.email);
//                     setAbhaAddress(response.data.abhaProfile.phrAddress);
//                     setPerAreaCode(response.data.abhaProfile.pinCode);
//                     setResAreaCode(response.data.abhaProfile.pinCode);

//                     try {

//                         const response = await axios.post(`${baseUrl}AbhaAddressSuggestion`, {
//                             headers: {
//                                 "Content-Type": "application/json",
//                             },
//                         });

//                         console.log("response", response.data.abhaAddressList);
//                         if (response.data && response.data.abhaAddressList) {
//                             setAbhaAddress1Data(response.data.abhaAddressList);
//                             { console.log("ABHA Address Data:", AbhaAddress1Data) }
//                         } else {
//                             toast.error(response.error.message || 'Failed to Suggest Abha Address');
//                         }
//                     } catch (error) {
//                         console.error('Error fetching ABHA details:', error);
//                         toast.error('An error occurred while fetching ABHA details Please try again in some time.');
//                     }
//                 }
//             } else {
//                 toast.error(response.error.message || 'Failed to generate OTP');
//             }
//         } catch (error) {
//             console.error('Error fetching ABHA details:', error);
//             toast.error('An error occurred while fetching ABHA details Please try again in some time.');
//         }

//     };

//     const handleAbhaAddressChange = async (selectedAddress) => {
//         if (selectedAddress) {
//             try {
//                 const formData = new FormData();

//                 formData.append('abhaAddress', selectedAddress);
//                 const response = await axios.post(`${baseUrl}Post_abha_address`, formData, {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 });

//                 console.log("response", response);
//                 if (response.data.txnId) {
//                     const healthId = response.data.healthIdNumber
//                     try {
//                         const formData = new FormData();

//                         formData.append('ABHANumber', healthId);
//                         const response = await axios.post(`${baseUrl}UserAuthenticate`, formData, {
//                             headers: {
//                                 "Content-Type": "multipart/form-data",
//                             },
//                         });

//                         console.log("response", response);
//                         if (response.data.txnId) {

//                             toast.success(response.data.healthIdNumber);

//                         } else {
//                             toast.error(response.data.error.message || 'Failed to process the address');
//                         }
//                     } catch (error) {
//                         console.error('Error fetching ABHA details:', error);
//                         toast.error('An error occurred while fetching ABHA details');
//                     }



//                 } else {
//                     toast.error(response.data.error.message || 'Failed to process the address');
//                 }
//             } catch (error) {
//                 console.error('Error fetching ABHA details:', error);
//                 toast.error('An error occurred while fetching ABHA details');
//             }
//         } else {
//             toast.error('Invalid ABHA address or consent not checked.');
//         }
//     };

//     const [OTP, setOTP] = useState('');
//     const [MobileNo, setMobile] = useState('');
//     const [consentChecked, setConsentChecked] = useState(false);
//     const handleCChange = (event) => {
//         setConsentChecked(event.target.checked);
//     };

//     return (
//         <Layout>
//             <Container maxWidth="lg">
//                 {/* <Paper elevation={3} className={classes.formContainer}> */}
//                 <Typography variant="h4" className={classes.formTitle}>
//                     Registration Form
//                 </Typography>
//                 <form onSubmit={handleSubmit}>
//                     <Grid container spacing={3}>

//                         {/* Personal Information */}
//                         <Grid item xs={12} sm={2}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Unit"
//                                 name="unit"
//                                 value={unit}
//                                 onChange={(event) => {
//                                     setUnit(event.target.value);
//                                 }}
//                             >
//                                 <MenuItem value="1">Unit-1</MenuItem>
//                                 <MenuItem value="2">Unit-2</MenuItem>

//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={2}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Prefix"
//                                 name="Prefix"
//                                 value={prefix}
//                                 onChange={(event) => {
//                                     setPrefix(event.target.value);
//                                 }}
//                             >
//                                 {prefixData.map(country => (
//                                     <MenuItem key={country.id} value={country.prefix}>
//                                         {country.prefix}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="First Name"
//                                 name="firstName"
//                                 value={firstName}
//                                 onChange={(event) => {
//                                     setFirstName(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Middle Name"
//                                 name="middleName"
//                                 value={middleName}
//                                 onChange={(event) => {
//                                     setMiddleName(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Last Name"
//                                 name="lastName"
//                                 value={lastName}
//                                 onChange={(event) => {
//                                     setLastName(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         {/* ABDM Information */}
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Aadhar Number"
//                                 name="aadharNo"
//                                 value={aadharNo}
//                                 inputProps={{ maxLength: 12, minlength: 12 }}
//                                 onChange={(event) => {
//                                     setAadharNo(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="ABHA ID"
//                                 name="abhaId"
//                                 value={abhaId}
//                                 inputProps={{ maxLength: 14, minlength: 14 }}
//                                 onChange={(event) => {
//                                     setAbhaId(event.target.value);
//                                 }}
//                             />
//                             <Typography variant="body2" color="error">
//                                 Don't have ABHA ID?{' '}
//                                 <Link href="#" onClick={handleClickOpen} style={{ color: 'inherit', cursor: 'pointer' }}>
//                                     Create One
//                                 </Link>
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="ABHA Address"
//                                 name="abhaAddress"
//                                 value={abhaAddress}
//                                 onChange={(event) => {
//                                     setAbhaAddress(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Gender"
//                                 name="gender"
//                                 value={gender}
//                                 onChange={(event) => {
//                                     setGender(event.target.value);
//                                 }}
//                             >
//                                 <MenuItem value="M">Male</MenuItem>
//                                 <MenuItem value="F">Female</MenuItem>
//                                 <MenuItem value="Other">Other</MenuItem>
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Mobile Number"
//                                 required
//                                 value={mobileNo}
//                                 inputProps={{ maxLength: 10, minlength: 10 }}
//                                 helperText={errors.mobileNo ? errors.mobileNo : "Minimum 10 digits required"}
//                                 onChange={(event) => {
//                                     const value = event.target.value;
//                                     if (/^\d*$/.test(value)) {
//                                         setMobileNo(value);
//                                     }
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Email"
//                                 name="email"
//                                 value={email}
//                                 onChange={(event) => {
//                                     setEmail(event.target.value);
//                                 }}

//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 type="date"
//                                 label="Date of Birth"
//                                 name="dob"
//                                 value={dob}
//                                 onChange={(event) => {
//                                     setDob(event.target.value);
//                                 }}
//                                 InputLabelProps={{
//                                     shrink: true,
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Contact Number"
//                                 value={contactNo}
//                                 helperText={errors.contactNo ? errors.contactNo : "Minimum 10 digits required"}
//                                 inputProps={{ maxLength: 10, minlength: 10 }}
//                                 onChange={(event) => {
//                                     const value = event.target.value;
//                                     if (/^\d*$/.test(value)) {
//                                         setContactNo(value);
//                                     }
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <Divider />
//                         </Grid>
//                         {/* Permanent Address */}
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Permanent Address"
//                                 name="perAddress"
//                                 value={perAddress}
//                                 onChange={(event) => {
//                                     setPerAddress(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Permanent Country"
//                                 name="perCountry"
//                                 value={perCountry}
//                                 onChange={(event) => {
//                                     getState(event.target.value);
//                                 }}
//                             >
//                                 {Country.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.countryName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Permanent State"
//                                 name="perState"
//                                 value={perState}
//                                 onChange={(event) => {
//                                     getDistrict(event.target.value);
//                                 }}
//                             >
//                                 {State.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.stateName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Permanent District"
//                                 name="perDistrict"
//                                 value={perDistrict}
//                                 onChange={(event) => {
//                                     getTaluka(event.target.value);
//                                 }}
//                             >
//                                 {District.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.districtName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Permanent Taluka"
//                                 name="perTaluka"
//                                 value={perTaluka || ''}  // Default to an empty string if perTaluka is undefined
//                                 onChange={(event) => {
//                                     const selectedTalukaId = event.target.value;
//                                     setPerTaluka(selectedTalukaId);  // Set the selected taluka value in state
//                                     getTown(selectedTalukaId);       // Fetch town data based on the selected taluka
//                                 }}
//                             >
//                                 {Taluka && Taluka.length > 0 ? (
//                                     Taluka.map(taluka => (
//                                         <MenuItem key={taluka.id} value={taluka.id}>
//                                             {taluka.taluka}
//                                         </MenuItem>
//                                     ))
//                                 ) : (
//                                     <MenuItem disabled>No taluka available</MenuItem> // Handle empty state
//                                 )}
//                             </TextField>

//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Permanent Town"
//                                 name="perTown"
//                                 value={perTown}
//                                 onChange={(event) => {
//                                     setPerTown(event.target.value);
//                                 }}
//                             >
//                                 {Town.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.town}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Permanent Area Code"
//                                 name="perAreaCode"
//                                 value={perAreaCode}
//                                 onChange={(event) => {
//                                     setPerAreaCode(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <Divider />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <FormControlLabel
//                                 control={
//                                     <Checkbox
//                                         checked={sameAddress}
//                                         onChange={handleCheckboxChange}
//                                         name="sameAddress"
//                                         color="primary"
//                                     />
//                                 }
//                                 label="Residential address is the same as permanent address"
//                             />
//                         </Grid>
//                         {/* Residential Address */}
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Residential Address"
//                                 name="resAddress"
//                                 value={resAddress}
//                                 onChange={(event) => {
//                                     setResAddress(event.target.value);
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Residential Country"
//                                 name="resCountry"
//                                 value={resCountry}
//                                 onChange={(event) => {
//                                     getRState(event.target.value);
//                                 }}
//                             >
//                                 {Country.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.countryName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Residential State"
//                                 name="resState"
//                                 value={resState}
//                                 onChange={(event) => {
//                                     getRDistrict(event.target.value);
//                                 }}
//                             >
//                                 {State.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.stateName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Residential District"
//                                 name="resDistrict"
//                                 value={resDistrict}
//                                 onChange={(event) => {
//                                     getRTaluka(event.target.value);
//                                 }}
//                             >
//                                 {District.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.districtName}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Residential Taluka"
//                                 name="resTaluka"
//                                 value={resTaluka || ''}  // Default to an empty string if resTaluka is undefined
//                                 onChange={(event) => {
//                                     const selectedTalukaId = event.target.value;
//                                     setResTaluka(selectedTalukaId);  // Set the selected residential taluka value in state
//                                     getRTown(selectedTalukaId);      // Fetch town data based on the selected taluka
//                                 }}
//                             >
//                                 {Taluka && Taluka.length > 0 ? (
//                                     Taluka.map(taluka => (
//                                         <MenuItem key={taluka.id} value={taluka.id}>
//                                             {taluka.taluka}
//                                         </MenuItem>
//                                     ))
//                                 ) : (
//                                     <MenuItem disabled>No taluka available</MenuItem>  // Handle empty state
//                                 )}
//                             </TextField>

//                         </Grid>
//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 label="Residential Town"
//                                 name="resTown"
//                                 value={resTown}
//                                 onChange={(event) => {
//                                     setResTown(event.target.value);
//                                 }}
//                             >
//                                 {Town.map(country => (
//                                     <MenuItem key={country.id} value={country.id}>
//                                         {country.town}
//                                     </MenuItem>
//                                 ))}
//                             </TextField>
//                         </Grid>

//                         <Grid item xs={12} sm={4}>
//                             <TextField
//                                 variant="outlined"
//                                 fullWidth
//                                 label="Residential Area Code"
//                                 name="resAreaCode"
//                                 value={resAreaCode}
//                                 onChange={(event) => {
//                                     setResAreaCode(event.target.value);
//                                 }}
//                             />
//                         </Grid>



//                         <Grid item xs={12} >
//                             <Divider />
//                         </Grid>

//                     </Grid>
//                     <Button
//                         type="submit"
//                         variant="contained"
//                         className={classes.submitButton}
//                     >
//                         Submit
//                     </Button>
//                 </form>
//                 <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
//                     <DialogTitle>ABHA Form</DialogTitle>
//                     {!txnId ?
//                         <form onSubmit={handleAadharChange}>
//                             <DialogContent>
//                                 <Grid container>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             label="Aadhar Number"
//                                             name="aadharNo"
//                                             value={aadharNo}
//                                             inputProps={{ maxLength: 12, minLength: 12 }}
//                                             onChange={(event) => setAadharNo(event.target.value)}
//                                         />
//                                     </Grid>
//                                     <Grid item xs={12}>
//                                         <FormControlLabel

//                                             control={<Checkbox checked={consentChecked} onChange={handleCChange} />}
//                                             label="I consent to the terms and conditions."
//                                         />
//                                     </Grid>
//                                 </Grid>

//                             </DialogContent>
//                             <DialogActions>
//                                 <Button onClick={handleClose} color="primary">
//                                     Cancel
//                                 </Button>
//                                 <Button type='submit' color="primary" variant="contained">
//                                     GET OTP
//                                 </Button>
//                             </DialogActions>
//                         </form>
//                         :
//                         <form onSubmit={handleMobileChange}>
//                             <DialogContent>
//                                 <Grid container spacing={3}>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField

//                                             variant="outlined"
//                                             fullWidth
//                                             label="OTP"
//                                             name="OTP"
//                                             inputProps={{ maxLength: 6 }} // Limit to 10 digits
//                                             value={OTP}
//                                             onChange={(event) => setOTP(event.target.value)}
//                                         />
//                                     </Grid>
//                                     <Grid item xs={12} sm={4}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             label="Mobile No."
//                                             name="Mobile No."
//                                             value={MobileNo}
//                                             inputProps={{ maxLength: 10, minLength: 10 }}
//                                             onChange={(event) => setMobile(event.target.value)}
//                                         />
//                                     </Grid>

//                                     <Grid item xs={12} sm={4}>
//                                         <FormControl variant="outlined" fullWidth>
//                                             <InputLabel id="abha-address-label">ABHA Address</InputLabel>
//                                             <Select
//                                                 labelId="abha-address-label"
//                                                 value={AbhaAddress1}
//                                                 onChange={(event) => {
//                                                     const selectedValue = event.target.value;
//                                                     setAbhaAddress1(selectedValue); // Update state with the selected address
//                                                     handleAbhaAddressChange(selectedValue); // Call the handler
//                                                 }}
//                                                 label="ABHA Address"
//                                                 disabled={!isMobileVerified} // Disable until mobile verification is done
//                                             >
//                                                 {AbhaAddress1Data.length > 0 ? (
//                                                     AbhaAddress1Data.map((address) => (
//                                                         <MenuItem key={address} value={address}>
//                                                             {address}
//                                                         </MenuItem>
//                                                     ))
//                                                 ) : (
//                                                     <MenuItem disabled>No options available</MenuItem>
//                                                 )}
//                                             </Select>
//                                         </FormControl>
//                                     </Grid>

//                                 </Grid>

//                             </DialogContent>
//                             <DialogActions>
//                                 <Button onClick={handleClose} color="primary">
//                                     Cancel
//                                 </Button>
//                                 <Button type='submit' color="primary" variant="contained">
//                                     SUBMIT
//                                 </Button>
//                             </DialogActions>
//                         </form>
//                     }
//                 </Dialog>
//             </Container>
//         </Layout>
//     );
// };

// export default Form;






import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { GETAPI, POSTAPI } from '../Redux/Slice/Address';
import { toast } from 'react-toastify';
import { BookPosts } from '../Redux/Slice/Registration';
import axios from 'axios';
import { baseUrl } from '../Redux/Slice/Aadhar';
import forge from 'node-forge'
import { Container, TextField, Button, Grid, Typography, Paper, MenuItem, Divider, Box, FormControlLabel, Checkbox, DialogActions, Dialog, DialogTitle, DialogContent, Select, InputLabel, FormControl, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const Form = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    // form state
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [unit, setUnit] = useState('');
    const [prefixData, setPrefixData] = useState([]);
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [EditmobileNo, setEditMobileNo] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [resAddress, setResAddress] = useState('');
    const [resCountry, setResCountry] = useState('');
    const [resState, setResState] = useState('');
    const [resDistrict, setResDistrict] = useState('');
    const [resTaluka, setResTaluka] = useState('');
    const [resTown, setResTown] = useState('');
    const [resAreaCode, setResAreaCode] = useState('');
    const [perAddress, setPerAddress] = useState('');
    const [perCountry, setPerCountry] = useState('');
    const [perState, setPerState] = useState('');
    const [perDistrict, setPerDistrict] = useState('');
    const [perTaluka, setPerTaluka] = useState('');
    const [perTown, setPerTown] = useState('');
    const [perAreaCode, setPerAreaCode] = useState('');
    const [aadharNo, setAadharNo] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [abhaId, setAbhaId] = useState('');
    const [abhaAddress, setAbhaAddress] = useState('');
    const [sameAddress, setSameAddress] = useState(false);
    const [AbhaAddress1Data, setAbhaAddress1Data] = useState([]);
    const [AbhaAddress1, setAbhaAddress1] = useState('');
    const [isMobileVerified, setIsMobileVerified] = useState(false); // New state variable
    const [errors, setErrors] = useState({});
    const appendFormData = (formData, key, value) => {
        formData.append(key, value ? value : '0');
    };
    // ABHA
    const [open, setOpen] = useState(false);
    const [AbhaDetails, setAbhaDetails] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTxnId(false);
        setAadharNo('');
        setConsentChecked(false);
        setOTP('');
        setMobile('');
    };
    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
    4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
    wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
    68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
    8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
    Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
    rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
    ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
    0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
    iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
    o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
    IzQpnSVDUVEzv17grVAw078CAwEAAQ==
    -----END PUBLIC KEY-----`;


    const [txnId, setTxnId] = useState(false); // State for transaction ID

    const formatDate = (dateString) => {
        // Assuming dateString is in "DD-MM-YYYY" format
        const parts = dateString.split('-');
        if (parts.length === 3) {
            return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to "YYYY-MM-DD" format
        }
        return '';
    };
    const handleAadharChange = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setAadhar(aadharNo)
        if (aadharNo.length === 12 && consentChecked) {
            try {
                // Encrypt Aadhar number
                const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(aadharNo, 'RSA-OAEP');
                const encryptedAadharNo = forge.util.encode64(encryptedData);

                const formData = new FormData();
                formData.append('loginId', encryptedAadharNo);

                const response = await axios.post(`${baseUrl}request-otp-aadhar`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log("response", response);
                if (response.data.message) {
                    toast.success(response.data.message);
                    setTxnId(true);


                } else {
                    toast.error(response.error.message || 'Failed to generate OTP');
                }
            } catch (error) {
                console.error('Error fetching ABHA details:', error);
                toast.error('An error occurred while fetching ABHA details');
            }
        } else {
            toast.error('Invalid Aadhar number or consent not checked.');
        }
    };

    const handleMobileChange = async (event) => {
        event.preventDefault(); // Prevent default form submission
        if (MobileNo) { setEditMobileNo(MobileNo) }

        try {
            // Encrypt Aadhar number
            const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(OTP, 'RSA-OAEP');
            const encryptedOTP = forge.util.encode64(encryptedData);

            // const formData = new FormData();
            // formData.append('otpValue', encryptedOTP);
            const body = {
                "otpValue": encryptedOTP,
                "mobile": MobileNo
            }
            const response = await axios.post(`${baseUrl}verify-by-aadhaar`, body, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("response", response);
            if (response.data.message) {
                setIsMobileVerified(true);
                toast.success(response.data.message);
                if (response.data.message === 'This account already exist') {
                    toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`)
                    setAbhaDetails(response.data.abhaProfile);
                    setFirstName(response.data.abhaProfile.firstName);
                    setMiddleName(response.data.abhaProfile.middleName);
                    setLastName(response.data.abhaProfile.lastName);
                    setAbhaId(response.data.abhaProfile.abhaNumber);
                    setResAddress(response.data.abhaProfile.address);
                    setPerAddress(response.data.abhaProfile.address);
                    setDob(formatDate(response.data.abhaProfile.dob));
                    setGender(response.data.abhaProfile.gender);
                    setAadharNo(aadhar);
                    setMobileNo(response.data.abhaProfile.mobile !== null ? response.data.abhaProfile.mobile
                        : EditmobileNo
                    );
                    setEmail(response.data.abhaProfile.email);
                    setAbhaAddress(response.data.abhaProfile.phrAddress);
                    setPerAreaCode(response.data.abhaProfile.pinCode);
                    setResAreaCode(response.data.abhaProfile.pinCode);

                    try {

                        const response = await axios.post(`${baseUrl}AbhaAddressSuggestion`, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });

                        console.log("response", response.data.abhaAddressList);
                        if (response.data && response.data.abhaAddressList) {
                            setAbhaAddress1Data(response.data.abhaAddressList);
                            { console.log("ABHA Address Data:", AbhaAddress1Data) }
                        } else {
                            toast.error(response.error.message || 'Failed to Suggest Abha Address');
                        }
                    } catch (error) {
                        console.error('Error fetching ABHA details:', error);
                        toast.error('An error occurred while fetching ABHA details Please try again in some time.');
                    }
                }
            } else {
                toast.error(response.error.message || 'Failed to generate OTP');
            }
        } catch (error) {
            console.error('Error fetching ABHA details:', error);
            toast.error('An error occurred while fetching ABHA details Please try again in some time.');
        }

    };

    const handleAbhaAddressChange = async (selectedAddress) => {
        if (selectedAddress) {
            try {
                const formData = new FormData();

                formData.append('abhaAddress', selectedAddress);
                const response = await axios.post(`${baseUrl}Post_abha_address`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log("response", response);
                if (response.data.txnId) {
                    const healthId = response.data.healthIdNumber
                    try {
                        const formData = new FormData();

                        formData.append('ABHANumber', healthId);
                        const response = await axios.post(`${baseUrl}UserAuthenticate`, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });

                        console.log("response", response);
                        if (response.data.txnId) {

                            toast.success(response.data.healthIdNumber);

                        } else {
                            toast.error(response.data.error.message || 'Failed to process the address');
                        }
                    } catch (error) {
                        console.error('Error fetching ABHA details:', error);
                        toast.error('An error occurred while fetching ABHA details');
                    }



                } else {
                    toast.error(response.data.error.message || 'Failed to process the address');
                }
            } catch (error) {
                console.error('Error fetching ABHA details:', error);
                toast.error('An error occurred while fetching ABHA details');
            }
        } else {
            toast.error('Invalid ABHA address or consent not checked.');
        }
    };

    const [OTP, setOTP] = useState('');
    const [MobileNo, setMobile] = useState('');
    const [consentChecked, setConsentChecked] = useState(false);
    const handleCChange = (event) => {
        setConsentChecked(event.target.checked);
    };

    const resetForm = () => {
        setFirstName('');
        setUnit('');
        setPrefix('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setMobileNo('');
        setContactNo('');
        setEmail('');
        setDob('');
        setResAddress('');
        setResCountry('');
        setResState('');
        setResDistrict('');
        setResTaluka('');
        setResTown('');
        setResAreaCode('');
        setPerAddress('');
        setPerCountry('');
        setPerState('');
        setPerDistrict('');
        setPerTaluka('');
        setPerTown('');
        setPerAreaCode('');
        setAadharNo('');
        setAbhaId('');
        setAbhaAddress('');
        setSameAddress(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('Id', '0');
        formData.append('Unit', unit);
        formData.append('Prefix', prefix);
        formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
        formData.append('Gender', gender);
        formData.append('MobileNo', mobileNo);
        formData.append('Email', email);
        formData.append('Dob', dob);
        formData.append('ResAddress', resAddress);
        formData.append('MiddleName', middleName ? middleName : '');
        // appendFormData(formData, 'MiddleName', middleName);
        formData.append('PerAddress', perAddress);
        appendFormData(formData, 'ResCountry', resCountry);
        appendFormData(formData, 'ContactNo', contactNo);
        appendFormData(formData, 'ResState', resState);
        appendFormData(formData, 'ResDistrict', resDistrict);
        appendFormData(formData, 'ResTaluka', resTaluka);
        appendFormData(formData, 'ResTown', resTown);
        appendFormData(formData, 'ResAreaCode', resAreaCode);
        appendFormData(formData, 'PerCountry', perCountry);
        appendFormData(formData, 'PesState', perState);
        appendFormData(formData, 'PerDistrict', perDistrict);
        appendFormData(formData, 'PerTaluka', perTaluka);
        appendFormData(formData, 'PerTown', perTown);
        formData.append('PerAreaCode', perAreaCode);
        formData.append('AadharNo', aadharNo);
        formData.append('AbhaId', abhaId);
        formData.append('AbhaAddress', abhaAddress);
        const response = await dispatch(BookPosts(formData));
        console.log(response.payload.success)

        if (response.payload.success === true && response.payload.message === "Patient added successfully.") {
            toast.success("Patient Added successfully");
            resetForm();
        }
        else {
            toast.error(response.payload.message);
        }

    };
    // state,city,district
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [District, setDistrict] = useState([]);
    const [Taluka, setTaluka] = useState([]);
    const [Town, setTown] = useState([]);
    // Permanent
    const getState = async (id) => {
        setPerCountry(id);
        try {
            const result1 = await POSTAPI("State", id);
            if (result1) {
                setState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getDistrict = async (id) => {
        setPerState(id);
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getTaluka = async (id) => {
        setPerDistrict(id);
        try {
            const result1 = await POSTAPI("Taluka", id);
            // if (result1) {
            setTaluka(result1);
            console.log(result1)
            // }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getTown = async (id) => {
        setPerTaluka(id)
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    // Residential
    const getRState = async (id) => {
        setResCountry(id);
        try {
            const result1 = await POSTAPI("State", id);
            if (result1.length > 0) {
                setState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRDistrict = async (id) => {
        setResState(id);
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRTaluka = async (id) => {
        setResDistrict(id);
        try {
            const result1 = await POSTAPI("Taluka", id);
            if (result1) {
                setTaluka(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRTown = async (id) => {
        setResTaluka(id)
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getView = async () => {
        try {
            const result = await GETAPI("Country");
            if (result) {
                setCountry(result);
            }
            const result1 = await GETAPI("Prefix");
            if (result1) {
                setPrefixData(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {

        getView();
    }, []);
    const handleCheckboxChange = (e) => {
        setSameAddress(e.target.checked);
        if (e.target.checked) {
            setResAddress(perAddress);
            setResCountry(perCountry);
            setResState(perState);
            setResDistrict(perDistrict);
            setResTaluka(perTaluka);
            setResTown(perTown);
            setResAreaCode(perAreaCode);
        } else {
            // Reset residential address fields if unchecked
            setResAddress('');
            setResCountry('');
            setResState('');
            setResDistrict('');
            setResTaluka('');
            setResTown('');
            setResAreaCode('');
        }
    };
    return (
        <>
            <Layout />



            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/patient">Patient </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">Add Patient</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>Registration Form</h4>

                                                    <form onSubmit={handleSubmit}>
                                                        <Grid container spacing={3}>

                                                            {/* Personal Information */}
                                                            <Grid item xs={12} sm={2}>
                                                                <label >Unit<span className="login-danger">*</span></label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Unit"
                                                                    name="unit"
                                                                    value={unit}
                                                                    onChange={(event) => {
                                                                        setUnit(event.target.value);
                                                                    }}
                                                                >
                                                                    <option value="1">Unit-1</option>
                                                                    <option value="2">Unit-2</option>
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <label >Prefix</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Prefix"
                                                                    name="Prefix"
                                                                    value={prefix}
                                                                    onChange={(event) => {
                                                                        setPrefix(event.target.value);
                                                                    }}
                                                                >
                                                                    {prefixData.map(country => (
                                                                        <option key={country.id} value={country.prefix}>
                                                                            {country.prefix}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >First Name <span className="login-danger">*</span></label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    value={firstName}
                                                                    onChange={(event) => {
                                                                        setFirstName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Middle Name</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Middle Name"
                                                                    name="middleName"
                                                                    value={middleName}
                                                                    onChange={(event) => {
                                                                        setMiddleName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Last Name <span className="login-danger">*</span></label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    required
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    value={lastName}
                                                                    onChange={(event) => {
                                                                        setLastName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {/* ABDM Information */}
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Aadhar No.</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Aadhar Number"
                                                                    name="aadharNo"
                                                                    value={aadharNo}
                                                                    maxLength={12}
                                                                    minLength={12}
                                                                    // inputProps={{ maxLength: 12, minlength: 12 }}
                                                                    onChange={(event) => {
                                                                        setAadharNo(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >ABHA ID</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="ABHA ID"
                                                                    name="abhaId"
                                                                    value={abhaId}
                                                                    maxLength={14}
                                                                    minLength={14}
                                                                    onChange={(event) => {
                                                                        setAbhaId(event.target.value);
                                                                    }}
                                                                />
                                                                <Typography variant="body2" color="error">
                                                                    Don't have ABHA ID?{' '}
                                                                    <Link href="#" onClick={handleClickOpen} style={{ color: 'inherit', cursor: 'pointer' }}>
                                                                        Create One
                                                                    </Link>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >ABHA Address</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="ABHA Address"
                                                                    name="abhaAddress"
                                                                    value={abhaAddress}
                                                                    onChange={(event) => {
                                                                        setAbhaAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Gender</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    required
                                                                    select
                                                                    label="Gender"
                                                                    name="gender"
                                                                    value={gender}
                                                                    onChange={(event) => {
                                                                        setGender(event.target.value);
                                                                    }}
                                                                >
                                                                    <option value="M">Male</option>
                                                                    <option value="F">Female</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Mobile No.</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Mobile Number"
                                                                    required
                                                                    value={mobileNo}
                                                                    maxLength={10}
                                                                    minLength={10}
                                                                    // helperText={errors.mobileNo ? errors.mobileNo : "Minimum 10 digits required"}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (/^\d*$/.test(value)) {
                                                                            setMobileNo(value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Email</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Email"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(event) => {
                                                                        setEmail(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Date of Birth</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    type="date"
                                                                    label="Date of Birth"
                                                                    name="dob"
                                                                    value={dob}
                                                                    onChange={(event) => {
                                                                        setDob(event.target.value);
                                                                    }}
                                                                // InputLabelProps={{
                                                                //     shrink: true,
                                                                // }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Contact Number<span className="login-danger">*</span></label>

                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    required
                                                                    label="Contact Number"
                                                                    value={contactNo}
                                                                    maxLength={10}
                                                                    minLength={10}
                                                                    // helperText={errors.contactNo ? errors.contactNo : "Minimum 10 digits required"}
                                                                    // inputProps={{ maxLength: 10, minlength: 10 }}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (/^\d*$/.test(value)) {
                                                                            setContactNo(value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                            {/* Permanent Address */}
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent Address</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Permanent Address"
                                                                    name="perAddress"
                                                                    value={perAddress}
                                                                    onChange={(event) => {
                                                                        setPerAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent Country</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Country"
                                                                    name="perCountry"
                                                                    value={perCountry}
                                                                    onChange={(event) => {
                                                                        getState(event.target.value);
                                                                    }}
                                                                >
                                                                    <option>Select</option>
                                                                    {Country.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.countryName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent State</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent State"
                                                                    name="perState"
                                                                    value={perState}
                                                                    onChange={(event) => {
                                                                        getDistrict(event.target.value);
                                                                    }}
                                                                ><option>Select</option>
                                                                    {State.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.stateName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent District</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent District"
                                                                    name="perDistrict"
                                                                    value={perDistrict}
                                                                    onChange={(event) => {
                                                                        getTaluka(event.target.value);
                                                                    }}
                                                                ><option>Select</option>
                                                                    {District.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.districtName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent Taluka</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Taluka"
                                                                    name="perTaluka"
                                                                    value={perTaluka || ''}  // Default to an empty string if perTaluka is undefined
                                                                    onChange={(event) => {
                                                                        const selectedTalukaId = event.target.value;
                                                                        setPerTaluka(selectedTalukaId);  // Set the selected taluka value in state
                                                                        getTown(selectedTalukaId);       // Fetch town data based on the selected taluka
                                                                    }}
                                                                ><option>Select</option>
                                                                    {Taluka && Taluka.length > 0 ? (
                                                                        Taluka.map(taluka => (
                                                                            <option key={taluka.id} value={taluka.id}>
                                                                                {taluka.taluka}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option disabled>No taluka available</option> // Handle empty state
                                                                    )}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent Town</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Town"
                                                                    name="perTown"
                                                                    value={perTown}
                                                                    onChange={(event) => {
                                                                        setPerTown(event.target.value);
                                                                    }}
                                                                ><option>Select</option>
                                                                    {Town.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.town}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Permanent Area Code</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Permanent Area Code"
                                                                    name="perAreaCode"
                                                                    value={perAreaCode}
                                                                    onChange={(event) => {
                                                                        setPerAreaCode(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={sameAddress}
                                                                            onChange={handleCheckboxChange}
                                                                            name="sameAddress"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Residential address is the same as permanent address"
                                                                />
                                                            </Grid>
                                                            {/* Residential Address */}
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential Address</label>
                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Residential Address"
                                                                    name="resAddress"
                                                                    value={resAddress}
                                                                    onChange={(event) => {
                                                                        setResAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential Country</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Country"
                                                                    name="resCountry"
                                                                    value={resCountry}
                                                                    onChange={(event) => {
                                                                        getRState(event.target.value);
                                                                    }}
                                                                >
                                                                    <option>Select</option>
                                                                    {Country.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.countryName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential State</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential State"
                                                                    name="resState"
                                                                    value={resState}
                                                                    onChange={(event) => {
                                                                        getRDistrict(event.target.value);
                                                                    }}
                                                                >
                                                                    <option>Select</option>
                                                                    {State.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.stateName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential District</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential District"
                                                                    name="resDistrict"
                                                                    value={resDistrict}
                                                                    onChange={(event) => {
                                                                        getRTaluka(event.target.value);
                                                                    }}
                                                                ><option>Select</option>
                                                                    {District.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.districtName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential Taluka</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Taluka"
                                                                    name="resTaluka"
                                                                    value={resTaluka || ''}  // Default to an empty string if resTaluka is undefined
                                                                    onChange={(event) => {
                                                                        const selectedTalukaId = event.target.value;
                                                                        setResTaluka(selectedTalukaId);  // Set the selected residential taluka value in state
                                                                        getRTown(selectedTalukaId);      // Fetch town data based on the selected taluka
                                                                    }}
                                                                ><option>Select</option>
                                                                    {Taluka && Taluka.length > 0 ? (
                                                                        Taluka.map(taluka => (
                                                                            <option key={taluka.id} value={taluka.id}>
                                                                                {taluka.taluka}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option disabled>No taluka available</option>  // Handle empty state
                                                                    )}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential Town</label>
                                                                <select
                                                                    className="form-control"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Town"
                                                                    name="resTown"
                                                                    value={resTown}
                                                                    onChange={(event) => {
                                                                        setResTown(event.target.value);
                                                                    }}
                                                                ><option>Select</option>
                                                                    {Town.map(country => (
                                                                        <option key={country.id} value={country.id}>
                                                                            {country.town}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label >Residential Area Code</label>

                                                                <input
                                                                    className="form-control"
                                                                    fullWidth
                                                                    label="Residential Area Code"
                                                                    name="resAreaCode"
                                                                    value={resAreaCode}
                                                                    onChange={(event) => {
                                                                        setResAreaCode(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} >
                                                                <Divider />
                                                            </Grid>
                                                        </Grid>
                                                        <div class="doctor-submit text-end">
                                                            <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleSubmit}>Submit</button>
                                                            <button type="reset" className="btn btn-primary cancel-form" >Cancel</button>
                                                        </div>
                                                    </form>
                                                    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                                                        <DialogTitle>ABHA Form</DialogTitle>
                                                        {!txnId ?
                                                            <form onSubmit={handleAadharChange}>
                                                                <DialogContent>
                                                                    <Grid container>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <label >Aadhar Number</label>

                                                                            <input
                                                                                className="form-control"
                                                                                required
                                                                                fullWidth
                                                                                label="Aadhar Number"
                                                                                name="aadharNo"
                                                                                value={aadharNo}
                                                                                inputProps={{ maxLength: 12, minLength: 12 }}
                                                                                onChange={(event) => setAadharNo(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControlLabel

                                                                                control={<Checkbox checked={consentChecked} onChange={handleCChange} />}
                                                                                label="I consent to the terms and conditions."
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleClose} color="primary">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button type='submit' color="primary" variant="contained">
                                                                        GET OTP
                                                                    </Button>
                                                                </DialogActions>
                                                            </form>
                                                            :
                                                            <form onSubmit={handleMobileChange}>
                                                                <DialogContent>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <label >OTP<span className="login-danger">*</span></label>
                                                                            <input
                                                                                className="form-control"
                                                                                fullWidth
                                                                                required
                                                                                label="OTP"
                                                                                name="OTP"
                                                                                inputProps={{ maxLength: 6 }} // Limit to 10 digits
                                                                                value={OTP}
                                                                                onChange={(event) => setOTP(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <label >Mobile No.<span className="login-danger">*</span></label>

                                                                            <input
                                                                                className="form-control"
                                                                                fullWidth
                                                                                required
                                                                                label="Mobile No."
                                                                                name="Mobile No."
                                                                                value={MobileNo}
                                                                                inputProps={{ maxLength: 10, minLength: 10 }}
                                                                                onChange={(event) => setMobile(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <FormControl variant="outlined" fullWidth>

                                                                                <label >ABHA Address </label>

                                                                                <select
                                                                                    labelId="abha-address-label"
                                                                                    value={AbhaAddress1}
                                                                                    onChange={(event) => {
                                                                                        const selectedValue = event.target.value;
                                                                                        setAbhaAddress1(selectedValue); // Update state with the selected address
                                                                                        handleAbhaAddressChange(selectedValue); // Call the handler
                                                                                    }}
                                                                                    label="ABHA Address"
                                                                                    disabled={!isMobileVerified} // Disable until mobile verification is done
                                                                                >
                                                                                    {AbhaAddress1Data.length > 0 ? (
                                                                                        AbhaAddress1Data.map((address) => (
                                                                                            <option key={address} value={address}>
                                                                                                {address}
                                                                                            </option>
                                                                                        ))
                                                                                    ) : (
                                                                                        <option disabled>No options available</option>
                                                                                    )}
                                                                                </select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleClose} color="primary">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button type='submit' color="primary" variant="contained">
                                                                        SUBMIT
                                                                    </Button>
                                                                </DialogActions>
                                                            </form>
                                                        }
                                                    </Dialog>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Form