import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Typography, MenuItem, Divider, Box, FormControlLabel, Checkbox, DialogActions, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../Layout/Layout';
import { GETAPI, POSTAPI } from '../Redux/Slice/Address';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { editPosts } from '../Redux/Slice/Edit';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const Edit = () => {
    const [patientDetails, setPatientDetails] = useState([]);
    const formatDateString = (dateString) => {
        try {
            const dateObject = new Date(dateString); // Convert date string to Date object

            if (isNaN(dateObject.getTime())) {
                throw new Error('Invalid date string');
            }

            const formattedDate = dateObject.toISOString().split('T')[0]; // Format date without time part
            return formattedDate; // Return formatted date string
        } catch (error) {
            console.error('Error formatting date:', error.message);
            return null; // Return null or handle the error as per your application logic
        }
    };

    const { id } = useParams();
    const getPatientById = async () => {
        try {
            const result1 = await POSTAPI("GetAllEnrollPatientById", id);

            setPatientDetails(result1);
            setFirstName(result1.firstName);
            setUnit(result1.unit);
            setPrefix(result1.prefix);
            setMiddleName(result1.middleName);
            setLastName(result1.lastName);
            setGender(result1.gender);
            setMobileNo(result1.mobileNo);
            setContactNo(result1.contactNo);
            setEmail(result1.email);
            setDob(result1.dob);
            setResAddress(result1.resAddress);
            setResCountry(result1.resCountry);
            setResState(result1.resState);
            setResDistrict(result1.resDistrict);
            setResTaluka(result1.perTaluka);
            setResTown(result1.perTown);
            setResAreaCode(result1.resAreaCode);
            setPerAddress(result1.perAddress);
            setPerCountry(result1.perCountry);
            setPerState(result1.pesState);
            setPerDistrict(result1.perDistrict);
            setPerTaluka(result1.perTaluka);
            setPerTown(result1.perTown);
            setPerAreaCode(result1.perTown);
            setAadharNo(result1.aadharNo);
            setAbhaId(result1.abhaId);
            setAbhaAddress(result1.abhaAddress);
            console.log("patientDetails", result1)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        getPatientById();

    }, [id])
    const classes = useStyles();
    // form state
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [unit, setUnit] = useState('');
    const [prefixData, setPrefixData] = useState([]);
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [resAddress, setResAddress] = useState('');
    const [resCountry, setResCountry] = useState('');
    const [resState, setResState] = useState('');
    const [resDistrict, setResDistrict] = useState('');
    const [resTaluka, setResTaluka] = useState('');
    const [resTown, setResTown] = useState('');
    const [resAreaCode, setResAreaCode] = useState('');
    const [perAddress, setPerAddress] = useState('');
    const [perCountry, setPerCountry] = useState('');
    const [perState, setPerState] = useState('');
    const [perDistrict, setPerDistrict] = useState('');
    const [perTaluka, setPerTaluka] = useState('');
    const [perTown, setPerTown] = useState('');
    const [perAreaCode, setPerAreaCode] = useState('');
    const [aadharNo, setAadharNo] = useState('');
    const [abhaId, setAbhaId] = useState('');
    const [abhaAddress, setAbhaAddress] = useState('');
    const [sameAddress, setSameAddress] = useState(false);
    const [errors, setErrors] = useState({});
    const handleCheckboxChange = (e) => {
        setSameAddress(e.target.checked);
        if (e.target.checked) {
            setResAddress(perAddress);
            setResCountry(perCountry);
            setResState(perState);
            setResDistrict(perDistrict);
            setResTaluka(perTaluka);
            setResTown(perTown);
            setResAreaCode(perAreaCode);
        }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resetForm = () => {
        setFirstName('');
        setUnit('');
        setPrefix('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setMobileNo('');
        setContactNo('');
        setEmail('');
        setDob('');
        setResAddress('');
        setResCountry('');
        setResState('');
        setResDistrict('');
        setResTaluka('');
        setResTown('');
        setResAreaCode('');
        setPerAddress('');
        setPerCountry('');
        setPerState('');
        setPerDistrict('');
        setPerTaluka('');
        setPerTown('');
        setPerAreaCode('');
        setAadharNo('');
        setAbhaId('');
        setAbhaAddress('');
        setSameAddress(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('Id', id);
        formData.append('Unit', unit);
        formData.append('Prefix', prefix);
        formData.append('FirstName', firstName);
        formData.append('MiddleName', middleName);
        formData.append('LastName', lastName);
        formData.append('Gender', gender);
        formData.append('MobileNo', mobileNo);
        formData.append('ContactNo', contactNo);
        formData.append('Email', email);
        formData.append('Dob', dob);
        formData.append('ResAddress', resAddress);
        formData.append('ResCountry', resCountry);
        formData.append('ResState', resState);
        formData.append('ResDistrict', resDistrict);
        formData.append('ResTaluka', resTaluka);
        formData.append('ResTown', resTown);
        formData.append('ResAreaCode', resAreaCode);
        formData.append('PerAddress', perAddress);
        formData.append('PerCountry', perCountry);
        formData.append('PesState', perState);
        formData.append('PerDistrict', perDistrict);
        formData.append('PerTaluka', perTaluka);
        formData.append('PerTown', perTown);
        formData.append('PerAreaCode', perAreaCode);
        formData.append('AadharNo', aadharNo);
        formData.append('AbhaId', abhaId);
        formData.append('AbhaAddress', abhaAddress);
        const response = await dispatch(editPosts(formData));
        console.log(response.payload.success)

        if (response.payload.success === true && response.payload.message === "Patient updated successfully.") {
            toast.success("Patient Updated Successfully");
            getPatientById(id);
        }
        else {
            toast.error(response.payload.message);
        }

    };
    // state,city,district
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [District, setDistrict] = useState([]);
    const [Taluka, setTaluka] = useState([]);
    const [Town, setTown] = useState([]);

    const [RState, setRState] = useState([]);
    const [RDistrict, setRDistrict] = useState([]);
    const [RTaluka, setRTaluka] = useState([]);
    const [RTown, setRTown] = useState([]);
    // Fetch functions for Permanent address
    const getState = async (id) => {
        try {
            const result1 = await POSTAPI("State", id);
            if (result1) {
                setState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (perCountry !== null) {
            getState(perCountry);
        }
    }, [perCountry]);

    const getDistrict = async (id) => {
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (perState !== null) {
            getDistrict(perState);
        }
    }, [perState]);

    const getTaluka = async (id) => {
        try {
            const result1 = await POSTAPI("Taluka", id);
            if (result1) {
                setTaluka(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (perDistrict !== null) {
            getTaluka(perDistrict);
        }
    }, [perDistrict]);

    const getTown = async (id) => {
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (perTaluka !== null) {
            getTown(perTaluka);
        }
    }, [perTaluka]);

    // Fetch functions for Residential address
    const getRState = async (id) => {
        try {
            const result1 = await POSTAPI("State", id);
            if (result1.length > 0) {
                setRState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (resCountry !== null) {
            getRState(resCountry);
        }
    }, [resCountry]);

    const getRDistrict = async (id) => {
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setRDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (resState !== null) {
            getRDistrict(resState);
        }
    }, [resState]);

    const getRTaluka = async (id) => {
        try {
            const result1 = await POSTAPI("Taluka", id);
            if (result1) {
                setRTaluka(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (resDistrict !== null) {
            getRTaluka(resDistrict);
        }
    }, [resDistrict]);

    const getRTown = async (id) => {
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setRTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (resTaluka !== null) {
            getRTown(resTaluka);
        }
    }, [resTaluka]);
    const getView = async () => {
        try {
            const result = await GETAPI("Country");
            if (result) {
                setCountry(result);
            }
            const result1 = await GETAPI("Prefix");
            if (result1) {
                setPrefixData(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {

        getView();
    }, []);
    return (
        <>
            <Layout />




            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">Staffs </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">Edit Staffs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>Edit Patient</h4>
                                                    <form onSubmit={handleSubmit}>
                                                        <Grid container spacing={3}>

                                                            {/* Personal Information */}
                                                            <Grid item xs={12} sm={2}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Unit"
                                                                    name="unit"
                                                                    value={unit}
                                                                    onChange={(event) => {
                                                                        setUnit(event.target.value);
                                                                    }}
                                                                >
                                                                    <MenuItem value="1">Unit-1</MenuItem>
                                                                    <MenuItem value="2">Unit-2</MenuItem>

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Prefix"
                                                                    name="Prefix"
                                                                    value={prefix}
                                                                    onChange={(event) => {
                                                                        setPrefix(event.target.value);
                                                                    }}
                                                                >
                                                                    {prefixData.map(country => (
                                                                        <MenuItem key={country.id} value={country.prefix}>
                                                                            {country.prefix}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    value={firstName}
                                                                    onChange={(event) => {
                                                                        setFirstName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Middle Name"
                                                                    name="middleName"
                                                                    value={middleName}
                                                                    onChange={(event) => {
                                                                        setMiddleName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    value={lastName}
                                                                    onChange={(event) => {
                                                                        setLastName(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {/* ABDM Information */}
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Aadhar Number"
                                                                    name="aadharNo"
                                                                    value={aadharNo}
                                                                    inputProps={{ maxLength: 12, minlength: 12 }}
                                                                    onChange={(event) => {
                                                                        setAadharNo(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="ABHA ID"
                                                                    name="abhaId"
                                                                    value={abhaId}
                                                                    inputProps={{ maxLength: 14, minlength: 14 }}
                                                                    onChange={(event) => {
                                                                        setAbhaId(event.target.value);
                                                                    }}
                                                                />

                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="ABHA Address"
                                                                    name="abhaAddress"
                                                                    value={abhaAddress}
                                                                    onChange={(event) => {
                                                                        setAbhaAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Gender"
                                                                    name="gender"
                                                                    value={gender}

                                                                    onChange={(event) => {
                                                                        setGender(event.target.value);
                                                                    }}
                                                                >
                                                                    <MenuItem value="Male">Male</MenuItem>
                                                                    <MenuItem value="Female">Female</MenuItem>
                                                                    <MenuItem value="Other">Other</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Mobile Number"
                                                                    value={mobileNo}
                                                                    inputProps={{ maxLength: 10, minlength: 10 }}
                                                                    helperText={errors.mobileNo ? errors.mobileNo : "Minimum 10 digits required"}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (/^\d*$/.test(value)) {
                                                                            setMobileNo(value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Email"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(event) => {
                                                                        setEmail(event.target.value);
                                                                    }}

                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    type="date"
                                                                    label="Date of Birth"
                                                                    name="dob"
                                                                    value={formatDateString(dob)}
                                                                    onChange={(event) => {
                                                                        setDob(event.target.value);
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Contact Number"
                                                                    value={contactNo}
                                                                    helperText={errors.contactNo ? errors.contactNo : "Minimum 10 digits required"}
                                                                    inputProps={{ maxLength: 10, minlength: 10 }}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (/^\d*$/.test(value)) {
                                                                            setContactNo(value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                            {/* Permanent Address */}
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Permanent Address"
                                                                    name="perAddress"
                                                                    value={perAddress}
                                                                    onChange={(event) => {
                                                                        setPerAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Country"
                                                                    name="perCountry"
                                                                    value={perCountry}
                                                                    onChange={(event) => {
                                                                        getState(event.target.value);
                                                                    }}
                                                                >
                                                                    {Country.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.countryName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent State"
                                                                    name="perState"
                                                                    value={perState}
                                                                    onChange={(event) => {
                                                                        getDistrict(event.target.value);
                                                                    }}
                                                                >
                                                                    {State.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.stateName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent District"
                                                                    name="perDistrict"
                                                                    value={perDistrict}
                                                                    onChange={(event) => {
                                                                        getTaluka(event.target.value);
                                                                    }}
                                                                >
                                                                    {District.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.districtName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Taluka"
                                                                    name="perTaluka"
                                                                    value={perTaluka}
                                                                    onChange={(event) => {
                                                                        getTown(event.target.value);
                                                                    }}
                                                                >
                                                                    {Taluka.length > 0 ? Taluka.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.taluka}
                                                                        </MenuItem>
                                                                    )) : <MenuItem disabled>
                                                                        No Data Found
                                                                    </MenuItem>}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Permanent Town"
                                                                    name="perTown"
                                                                    value={perTown}
                                                                    onChange={(event) => {
                                                                        setPerTown(event.target.value);
                                                                    }}
                                                                >
                                                                    {Town.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.town}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Permanent Area Code"
                                                                    name="perAreaCode"
                                                                    value={perAreaCode}
                                                                    onChange={(event) => {
                                                                        setPerAreaCode(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={sameAddress}
                                                                            onChange={handleCheckboxChange}
                                                                            name="sameAddress"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Residential address is the same as permanent address"
                                                                />
                                                            </Grid>
                                                            {/* Residential Address */}
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Residential Address"
                                                                    name="resAddress"
                                                                    value={resAddress}
                                                                    onChange={(event) => {
                                                                        setResAddress(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Country"
                                                                    name="resCountry"
                                                                    value={resCountry}
                                                                    onChange={(event) => {
                                                                        getRState(event.target.value);
                                                                    }}
                                                                >
                                                                    {Country.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.countryName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential State"
                                                                    name="resState"
                                                                    value={resState}
                                                                    onChange={(event) => {
                                                                        getRDistrict(event.target.value);
                                                                    }}
                                                                >
                                                                    {RState.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.stateName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential District"
                                                                    name="resDistrict"
                                                                    value={resDistrict}
                                                                    onChange={(event) => {
                                                                        getRTaluka(event.target.value);
                                                                    }}
                                                                >
                                                                    {RDistrict.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.districtName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Taluka"
                                                                    name="resTaluka"
                                                                    value={resTaluka}
                                                                    onChange={(event) => {
                                                                        getRTown(event.target.value);
                                                                    }}
                                                                >
                                                                    {RTaluka.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.taluka}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    select
                                                                    label="Residential Town"
                                                                    name="resTown"
                                                                    value={resTown}
                                                                    onChange={(event) => {
                                                                        setResTown(event.target.value);
                                                                    }}
                                                                >
                                                                    {RTown.map(country => (
                                                                        <MenuItem key={country.id} value={country.id}>
                                                                            {country.town}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label="Residential Area Code"
                                                                    name="resAreaCode"
                                                                    value={resAreaCode}
                                                                    onChange={(event) => {
                                                                        setResAreaCode(event.target.value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} >
                                                                <Divider />
                                                            </Grid>
                                                        </Grid>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            className={classes.submitButton}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Edit
