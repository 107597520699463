// import React, { useState } from 'react';
// import { AppBar, Toolbar, Typography, IconButton, Button, CssBaseline, Drawer, Divider, InputBase, Menu, MenuItem } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
// import { Sidebar, Menu as ProMenu, MenuItem as ProMenuItem, SubMenu, useProSidebar, ProSidebarProvider } from 'react-pro-sidebar';
// import { makeStyles } from '@material-ui/core/styles';
// import PieChartIcon from '@material-ui/icons/PieChart';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
// import DescriptionIcon from '@material-ui/icons/Description';
// import EventIcon from '@material-ui/icons/Event';
// import SearchIcon from '@material-ui/icons/Search';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import MailIcon from '@material-ui/icons/Mail';
// import Footer from './Footer';
// import { Link } from 'react-router-dom';
// import { FormatListBulleted, GroupAdd, People, VerifiedUser } from '@material-ui/icons';
// import AddCardIcon from '@mui/icons-material/AddCard';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingBottom: 55,
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   drawer: {
//     position: 'relative',
//     width: 240,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//   },
//   drawerOpen: {
//     marginTop: '65px',
//     width: 240,
//     overflowX: 'hidden',
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerClose: {
//     marginTop: '65px',
//     // width:'74px',
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: theme.spacing(7) + 8,
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(8) + 1,
//     },
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     marginLeft: 55,
//   },
// }));

// const Layout = ({ children }) => {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);

//   const handleDrawerToggle = () => {
//     setOpen(!open);
//   };

//   const [profileAnchorEl, setProfileAnchorEl] = useState(null);
//   const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
//   const [messagesAnchorEl, setMessagesAnchorEl] = useState(null);

//   const handleProfileMenuOpen = (event) => {
//     setProfileAnchorEl(event.currentTarget);
//   };

//   const handleNotificationsMenuOpen = (event) => {
//     setNotificationsAnchorEl(event.currentTarget);
//   };

//   const handleMessagesMenuOpen = (event) => {
//     setMessagesAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setProfileAnchorEl(null);
//     setNotificationsAnchorEl(null);
//     setMessagesAnchorEl(null);
//   };

//   return (
//     <ProSidebarProvider>
//       <div className={classes.root}>
//         <CssBaseline />
//         {/* Header */}
//         <AppBar position="fixed" className={classes.appBar}>
//           <Toolbar>
//             <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
//               <MenuIcon />
//             </IconButton>
//             <Typography variant="h6" style={{ flexGrow: 1 }}>
//               FLUX
//             </Typography>

//           </Toolbar>
//         </AppBar>
//         {/* Sidebar */}
//         <Drawer
//           variant="permanent"
//           className={`${classes.drawer} ${open ? classes.drawerOpen : classes.drawerClose}`}
//           classes={{
//             paper: open ? classes.drawerOpen : classes.drawerClose,
//           }}
//         >
//           <Sidebar>
//             <ProMenu iconShape="circle">
//               {/* <SubMenu icon={<PieChartIcon />} label="Charts">
//                 <ProMenuItem icon={<PieChartIcon />}>Pie charts</ProMenuItem>
//                 <ProMenuItem icon={<ShowChartIcon />}>Line charts</ProMenuItem>
//               </SubMenu> */}
//               <Divider />
//               <SubMenu icon={<People />} label="Patient">
//                 <Link to="/add-patient" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<GroupAdd />}>Add Patient</ProMenuItem>
//                 </Link>
//                 <Link to='/Patient' style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<FormatListBulleted />}>List of Patient</ProMenuItem>
//                 </Link>
//               </SubMenu>
//               <SubMenu icon={<AddCardIcon />} label="ABHA">
//                 <Link to="/download-abha" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<AddCardIcon />}>Download ABHA Card</ProMenuItem>
//                 </Link>
//                 <Link to="/verify-abha" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<AddCardIcon />}>ABHA Verification</ProMenuItem>
//                 </Link>
//                 <Link to="/verify-abha-otp" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<AddCardIcon />}>ABHA Verification by OTP</ProMenuItem>
//                 </Link>
//                 <Link to="/create-abha" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<AddCardIcon />}>ABHA Verification by Aadhar OTP</ProMenuItem>
//                 </Link>
//                 <Link to="/scan-abha" style={{ color: 'black', textDecoration: 'none' }}>
//                   <ProMenuItem icon={<AddCardIcon />}>Scan ABHA</ProMenuItem>
//                 </Link>
//               </SubMenu>

//             </ProMenu>
//           </Sidebar>
//         </Drawer>
//         {/* Main content area */}
//         <main className={classes.content}>
//           {children}
//         </main>
//         <Footer />
//       </div>
//     </ProSidebarProvider>
//   );
// };

// export default Layout;



import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Layout = () => {
  // State to handle submenu toggles
  const [patientsMenuOpen, setPatientsMenuOpen] = useState(false);
  const [abhaMenuOpen, setAbhaMenuOpen] = useState(false);

  // Toggle functions for menus
  const togglePatientsMenu = () => setPatientsMenuOpen(!patientsMenuOpen);
  const toggleAbhaMenu = () => setAbhaMenuOpen(!abhaMenuOpen);

  return (
    <>
      <div className="header">
        <div className="header-left">
          <Link to="/" className="logo">
            <img src="assets/img/FluxEMRLogo.png" width={35} height={35} alt="" />
            <span>FLUX EMR</span>
          </Link>
        </div>
        <a id="toggle_btn" href="javascript:void(0);">
          <img src="assets/img/icons/bar-icon.svg" alt="" />
        </a>
        <a id="mobile_btn" className="mobile_btn float-start" href="#sidebar">
          <img src="assets/img/icons/bar-icon.svg" alt="" />
        </a>
        <div className="top-nav-search mob-view">
          {/* <form>
            <input type="text" className="form-control" placeholder="Search here" />
            <a className="btn">
              <img src="assets/img/icons/search-normal.svg" alt="" />
            </a>
          </form> */}
        </div>
        {/* <ul className="nav user-menu float-end">
          <li className="nav-item dropdown d-none d-md-block">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <img src="assets/img/icons/note-icon-02.svg" alt="" />
              <span className="pulse" />
            </a>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span>Notifications</span>
              </div>
              <div className="drop-scroll">
                <ul className="notification-list">
                  <li className="notification-message">
                    <a href="activities.html">
                      <div className="media">
                        <span className="avatar">
                          <img
                            alt="John Doe"
                            src="assets/img/user.jpg"
                            className="img-fluid"
                          />
                        </span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">John Doe</span> added new
                            task
                            <span className="noti-title">
                              Patient appointment booking
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">4 mins ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <a href="activities.html">
                      <div className="media">
                        <span className="avatar">V</span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">Tarah Shropshire</span>
                            changed the task name
                            <span className="noti-title">
                              Appointment booking with payment gateway
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">6 mins ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <a href="activities.html">
                      <div className="media">
                        <span className="avatar">L</span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">Misty Tison</span> added
                            <span className="noti-title">Domenic Houston</span> and
                            <span className="noti-title">Claire Mapes</span> to
                            project
                            <span className="noti-title">
                              Doctor available module
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">8 mins ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <a href="activities.html">
                      <div className="media">
                        <span className="avatar">G</span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">Rolland Webber</span>
                            completed task
                            <span className="noti-title">
                              Patient and Doctor video conferencing
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">12 mins ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <a href="activities.html">
                      <div className="media">
                        <span className="avatar">V</span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">Bernardo Galaviz</span>
                            added new task
                            <span className="noti-title">Private chat module</span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">2 days ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <a href="activities.html">View all Notifications</a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown d-none d-md-block">
            <a
              href="javascript:void(0);"
              id="open_msg_box"
              className="hasnotifications nav-link"
            >
              <img src="assets/img/icons/note-icon-01.svg" alt="" />
              <span className="pulse" />
            </a>
          </li>
          <li className="nav-item dropdown has-arrow user-profile-list">
            <a
              href="#"
              className="dropdown-toggle nav-link user-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-names">
                <h5>Liam Michael </h5>
                <span>Admin</span>
              </div>
              <span className="user-img">
                <img src="assets/img/user-06.jpg" alt="Admin" />
              </span>
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="profile.html">
                My Profile
              </a>
              <a className="dropdown-item" href="edit-profile.html">
                Edit Profile
              </a>
              <a className="dropdown-item" href="settings.html">
                Settings
              </a>
              <Link className="dropdown-item" to="/">
                Logout
              </Link>
            </div>
          </li>
          <li className="nav-item ">
            <a href="settings.html" className="hasnotifications nav-link">
              <img src="assets/img/icons/setting-icon-01.svg" alt="" />
            </a>
          </li>
        </ul> */}
        {/* <div className="dropdown mobile-user-menu float-end">
          <a
            href="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical" />
          </a>
          <div className="dropdown-menu dropdown-menu-end">
            <a className="dropdown-item" href="profile.html">
              My Profile
            </a>
            <a className="dropdown-item" href="edit-profile.html">
              Edit Profile
            </a>
            <a className="dropdown-item" href="settings.html">
              Settings
            </a>
            <Link className="dropdown-item" to="/">
              Logout
            </Link>
          </div>
        </div> */}
      </div>
      {/* with toggle */}
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* <li className="menu-title">Main</li> */}

              {/* Patients Menu */}
              <li className="submenu">
                <Link to="#" onClick={togglePatientsMenu}>
                  <span className="menu-side">
                    <img src="assets/img/icons/menu-icon-01.svg" alt="" />
                  </span>
                  <span> Patients </span> <span className="menu-arrow" />
                </Link>
                <ul style={{ display: patientsMenuOpen ? "block" : "none" }}>
                  <li>
                    <Link to="/patient">List Patients</Link>
                  </li>
                  <li>
                    <Link to="/add-patient">Add Patient</Link>
                  </li>
                </ul>
              </li>

              {/* ABHA Menu */}
              <li className="submenu">
                <Link to="#" onClick={toggleAbhaMenu}>
                  <span className="menu-side">
                    <img src="assets/img/icons/menu-icon-08.svg" alt="" />
                  </span>
                  <span> ABHA Verification</span> <span className="menu-arrow" />
                </Link>
                <ul style={{ display: abhaMenuOpen ? "block" : "none" }}>
                  <li>
                    <Link to="/verify-abha-otp">By ABHA OTP</Link>
                  </li>
                  <li>
                    <Link to="/verify-aadhar-otp">By Aadhar OTP</Link>
                  </li>
                  <li>
                    <Link to="/verify-abha">By ABHA Address</Link>
                  </li>

                </ul>
              </li>
              <li className="submenu">
                <Link to="/download-abha">
                  <span class="menu-side">
                    <img src="assets/img/icons/menu-icon-10.svg" alt="" />
                  </span>
                  <span>Download ABHA Card</span></Link>
              </li>


              <li className="submenu">
                <Link to="/scan-abha">
                  <span class="menu-side">
                    <img src="assets/img/icons/menu-icon-10.svg" alt="" />
                  </span>
                  <span>Scan ABHA</span></Link>
              </li>
            </ul>


            {/* Logout Button */}
            <div className="logout-btn">
              <Link to="/">
                <span className="menu-side">
                  <img src="assets/img/icons/logout.svg" alt="" />
                </span>
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* without toogle */}
      {/* <div className="sidebar" id="sidebar">
        <div className="sidebar-inner">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">Main</li>
              <li className="submenu">
                <a href="#">
                  <span className="menu-side">
                    <img src="assets/img/icons/menu-icon-01.svg" alt="" />
                  </span>
                  <span> Patients </span> <span className="menu-arrow" />
                </a>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="/patient">List Patients</Link>
                  </li>
                  <li>
                    <Link to="/add-patient">Add Patient</Link>
                  </li>

                </ul>
              </li>
              <li className="submenu">
                <a href="#">
                  <span className="menu-side">
                    <img src="assets/img/icons/menu-icon-08.svg" alt="" />
                  </span>
                  <span> ABHA </span> <span className="menu-arrow" />
                </a>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="/verify-abha-otp">ABHA Verification by ABHA</Link>
                  </li>
                  <li>
                    <Link to="/verify-aadhar-otp">
                      ABHA Verification by Aadhar
                    </Link>
                  </li>
                  <li>
                    <Link to="/verify-abha">ABHA Verification</Link>
                  </li>
                  <li>
                    <Link to="/download-abha">Download ABHA Card</Link>
                  </li>
                  <li>
                    <Link to="/scan-abha">Scan ABHA</Link>
                  </li>

                </ul>
              </li>

            </ul>
            <div className="logout-btn">
              <Link to="/">
                <span className="menu-side">
                  <img src="assets/img/icons/logout.svg" alt="" />
                </span>
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </>

  )
}

export default Layout