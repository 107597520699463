import { configureStore } from "@reduxjs/toolkit";
import postsSlice from "./Slice/Registration";
import Edit from "./Slice/Edit";
const store = configureStore({
  reducer: {
    api: postsSlice,
    api:Edit,
  },
});

export default store;
