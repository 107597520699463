import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../Layout/Layout';
import { toast } from 'react-toastify';

import forge from 'node-forge'
import { baseUrl } from '../Redux/Slice/Aadhar';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        margin: theme.spacing(2),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const AadharOtp = () => {
    const classes = useStyles();
    const [aadharNo, setAadharNo] = useState('');
    const [MobileNo, setMobile] = useState('');
    const [result, setResult] = useState('');
    const [txnId, setTxnId] = useState(false); // State for transaction ID
    const [OTP, setOTP] = useState('');


    const reset = () => {
        setAadharNo('');
        setMobile('');
        setResult('');
        setOTP('');
    };



    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
    4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
    wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
    68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
    8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
    Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
    rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
    ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
    0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
    iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
    o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
    IzQpnSVDUVEzv17grVAw078CAwEAAQ==
    -----END PUBLIC KEY-----`;


    const handleAadharChange = async (event) => {
        event.preventDefault(); // Prevent default form submission

        if (aadharNo.length === 12) {
            try {
                // Encrypt Aadhar number
                const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(aadharNo, 'RSA-OAEP');
                const encryptedAadharNo = forge.util.encode64(encryptedData);

                const formData = new FormData();
                formData.append('loginId', encryptedAadharNo);

                const response = await axios.post(`${baseUrl}request-otp-aadhar`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log("response", response);
                if (response.data.message) {
                    toast.success(response.data.message);
                    setTxnId(true);
                    reset();

                } else {
                    toast.error(response.error.message || 'Failed to generate OTP');
                }
            } catch (error) {
                console.error('Error fetching ABHA details:', error);
                toast.error('An error occurred while fetching ABHA details');
            }
        } else {
            toast.error('Invalid Aadhar number or consent not checked.');
        }
    };
    const handleMobileChange = async (event) => {
        event.preventDefault(); // Prevent default form submission

        try {
            // Encrypt Aadhar number
            const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(OTP, 'RSA-OAEP');
            const encryptedOTP = forge.util.encode64(encryptedData);

            // const formData = new FormData();
            // formData.append('otpValue', encryptedOTP);
            const body = {
                "otpValue": encryptedOTP,
                "mobile": MobileNo
            }
            const response = await axios.post(`${baseUrl}verify-by-aadhaar`, body, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("response", response);
            if (response.data.message) {

                // toast.success(response.data.message);
                if (response.data.message === 'This account already exist') {
                    axios({
                        url: `${baseUrl}DownloadAbhaCard`,
                        method: 'POST',
                        responseType: 'blob', // Important to indicate that the response is binary data
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const now = new Date();
                        const formattedDate = now.toISOString().slice(0, 19).replace(/[-T:]/g, '');
                        const fileName = `AbhaCard_${formattedDate}.jpeg`;
                        // Set the download attribute with the dynamic filename
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                    });

                    reset();
                }
            } else {
                toast.error(response.error.message || 'Failed to generate OTP');
            }
        } catch (error) {
            console.error('Error fetching ABHA details:', error);
            // toast.error('An error occurred while fetching ABHA details Please try again in some time.');
        }

    };

    return (
        <>
            <Layout />


            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">ABHA </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">Download ABHA Card</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>Download ABHA Card</h4>
                                                    <div>


                                                        {!txnId ?

                                                            <form onSubmit={handleAadharChange}>
                                                                <Grid container>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >Aadhar Number<span className="login-danger">*</span></label>

                                                                        <input
                                                                            fullWidth
                                                                            className="form-control"
                                                                            name="aadharNo"
                                                                            value={aadharNo}
                                                                            maxLength={12}
                                                                            minLength={12}
                                                                            onChange={(event) => setAadharNo(event.target.value)}
                                                                        />
                                                                    </Grid>

                                                                </Grid>
                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleAadharChange}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form" onClick={reset}>Cancel</button>
                                                                </div>
                                                            </form>
                                                            :

                                                            <form onSubmit={handleMobileChange}>
                                                                <Grid container spacing={3}>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >Mobile No.<span className="login-danger">*</span></label>

                                                                        <input

                                                                            className="form-control"
                                                                            fullWidth
                                                                            label="Mobile No."
                                                                            name="Mobile No."
                                                                            value={MobileNo}
                                                                            maxLength={10}
                                                                            minLength={10}
                                                                            onChange={(event) => setMobile(event.target.value)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >OTP <span className="login-danger">*</span></label>

                                                                        <input
                                                                            className="form-control"

                                                                            fullWidth
                                                                            label="OTP"
                                                                            name="OTP"
                                                                            maxLength={6}
                                                                            value={OTP}
                                                                            onChange={(event) => setOTP(event.target.value)}
                                                                        />
                                                                    </Grid>

                                                                </Grid>
                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleMobileChange}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form" onClick={reset}>Cancel</button>
                                                                </div>
                                                            </form>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AadharOtp