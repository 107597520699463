// import React, { useState, useRef } from 'react';
// import Layout from '../Layout/Layout';
// import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { baseUrl } from '../Redux/Slice/Aadhar';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import forge from 'node-forge';

// const useStyles = makeStyles((theme) => ({
//     formContainer: {
//         padding: theme.spacing(4),
//         backgroundColor: '#f5f5f5',
//         borderRadius: '8px',
//     },
//     submitButton: {
//         marginTop: theme.spacing(4),
//         backgroundColor: '#007bff',
//         color: '#fff',
//         '&:hover': {
//             backgroundColor: '#0056b3',
//         },
//     },
//     formTitle: {
//         marginBottom: theme.spacing(2),
//         color: '#007bff',
//     },
// }));

// const AbhaOtp = () => {
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const [otp, setOtp] = useState('');
//     const [txnId, setTxnId] = useState(false);
//     const [mobileNo, setMobileNo] = useState('');
//     const [abhaSegments, setAbhaSegments] = useState((''));
//     const mobileRef = useRef(null);

//     const publicKeyPem = `-----BEGIN PUBLIC KEY-----
//     MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
//     4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
//     wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
//     68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
//     8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
//     Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
//     rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
//     ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
//     0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
//     iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
//     o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
//     IzQpnSVDUVEzv17grVAw078CAwEAAQ==
//     -----END PUBLIC KEY-----`;

//     const handleAbhaChange = (event) => {

//         setAbhaSegments(event.target.value)
//     };

//     const handleMobileChange = (event) => {
//         const { value } = event.target;
//         if (/^\d*$/.test(value) && value.length <= 10) {
//             setMobileNo(value);
//         }
//     };

//     const handleAbhaSubmit = async (event) => {
//         event.preventDefault();


//             try {
//                 const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(abhaSegments, 'RSA-OAEP');
//                 const encryptedAbhaNo = forge.util.encode64(encryptedData);

//                 const formData = new FormData();
//                 formData.append('loginId', encryptedAbhaNo);

//                 const response = await axios.post(`${baseUrl}request-otp-AabhaAddress`, formData, {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 });

//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     if (response.data.txnId === "BadRequest") {
//                         return;
//                     }
//                     setTxnId(true);
//                 } else {
//                     toast.error(response.error.message || 'Failed to generate OTP');
//                 }
//             } catch (error) {
//                 console.error('Error fetching ABHA details:', error);
//                 toast.error('An error occurred while fetching ABHA details');
//             }

//     };

//     const handleOtpSubmit = async (event) => {
//         event.preventDefault();
//         if (otp && mobileNo) {
//             try {
//                 const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
//                 const encryptedOTP = forge.util.encode64(encryptedOtp);

//                 const body = {
//                     otpValue: encryptedOTP,
//                     mobile: mobileNo
//                 };

//                 const response = await axios.post(`${baseUrl}verify-by-abhaAddress`, body, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });

//                 if (response.data.message) {
//                     toast.success(response.data.message);
//                     if (response.data.message === 'This account already exists') {
//                         toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`);
//                     }
//                 } else {
//                     toast.error(response.error.message || 'Failed to verify OTP');
//                 }
//             } catch (error) {
//                 console.error('Error verifying OTP:', error);
//                 toast.error('An error occurred while verifying OTP. Please try again.');
//             }
//         } else {
//             toast.error('Please enter both OTP and mobile number.');
//         }
//     };

//     return (
//         <Layout>
//             <Container maxWidth="lg">
//                 <div>
//                     <Typography variant="h6" className={classes.formTitle}>
//                         ABHA Verification by OTP (Registered Mobile Number on ABHA)
//                     </Typography>
//                     {!txnId ? (
//                         <form onSubmit={handleAbhaSubmit}>
//                             <Grid container spacing={2}>
//                                 <Grid item>
//                                     <TextField
//                                         variant="outlined"
//                                         label="Abha Address"
//                                         name="prefix"
//                                         value={abhaSegments}
//                                         onChange={handleAbhaChange}

//                                     />
//                                 </Grid>

//                             </Grid>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 className={classes.submitButton}
//                             >
//                                 Request OTP
//                             </Button>
//                         </form>
//                     ) : (
//                         <form onSubmit={handleOtpSubmit}>
//                             <Grid container spacing={2}>
//                                 <Grid item s={3}>
//                                     <TextField
//                                         variant="outlined"
//                                         label="Mobile Number"
//                                         value={mobileNo}
//                                         onChange={handleMobileChange}
//                                         inputProps={{ maxLength: 10 }} // Limit to 10 digits
//                                         inputRef={mobileRef}
//                                     />
//                                 </Grid>
//                                 <Grid item s={3}>
//                                     <TextField
//                                         variant="outlined"
//                                         label="OTP"
//                                         value={otp}
//                                         onChange={(e) => setOtp(e.target.value)}
//                                         inputProps={{ maxLength: 6 }} // Limit to 6 digits
//                                     />
//                                 </Grid>
//                             </Grid>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 className={classes.submitButton}
//                             >
//                                 Verify OTP
//                             </Button>
//                         </form>
//                     )}
//                 </div>
//             </Container>
//         </Layout>
//     );
// };

// export default AbhaOtp;




import React, { useRef, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch } from 'react-redux';
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { baseUrl } from '../Redux/Slice/Aadhar';
import Swal from 'sweetalert2';
import axios from 'axios';
import forge from 'node-forge';

const AbhaOtp = () => {

    const dispatch = useDispatch();
    const [otp, setOtp] = useState('');
    const [txnId, setTxnId] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [abhaSegments, setAbhaSegments] = useState((''));
    const mobileRef = useRef(null);

    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
        MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
        4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
        wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
        68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
        8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
        Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
        rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
        ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
        0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
        iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
        o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
        IzQpnSVDUVEzv17grVAw078CAwEAAQ==
        -----END PUBLIC KEY-----`;

    const handleAbhaChange = (event) => {

        setAbhaSegments(event.target.value)
    };

    const handleMobileChange = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNo(value);
        }
    };

    const handleAbhaSubmit = async (event) => {
        event.preventDefault();


        try {
            const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(abhaSegments, 'RSA-OAEP');
            const encryptedAbhaNo = forge.util.encode64(encryptedData);
            const formData = new FormData();
            formData.append('loginId', encryptedAbhaNo);

            const response = await axios.post(`${baseUrl}request-otp-AabhaAddress`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.message) {
                toast.success(response.data.message);
                if (response.data.txnId === "BadRequest") {
                    return;
                }
                setTxnId(true);
            } else {
                toast.error(response.error.message || 'Failed to generate OTP');
            }
        } catch (error) {
            console.error('Error fetching ABHA details:', error);
            toast.error('An error occurred while fetching ABHA details');
        }

    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        if (otp && mobileNo) {
            try {
                const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
                const encryptedOTP = forge.util.encode64(encryptedOtp);

                const body = {
                    otpValue: encryptedOTP,
                    mobile: mobileNo
                };

                const response = await axios.post(`${baseUrl}verify-by-abhaAddress`, body, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.data.message) {
                    
                    toast.success(response.data.message);
                    if (response.data.message === 'This account already exists') {
                        toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`);
                    }
                } else {
                    toast.error(response.error.message || 'Failed to verify OTP');
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                toast.error('An error occurred while verifying OTP. Please try again.');
            }
        } else {
            toast.error('Please enter both OTP and mobile number.');
        }
    };
    return (
        <>
            <Layout />
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">ABHA Verification</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">By ABHA OTP</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>ABHA Verification by OTP (Registered Mobile Number on ABHA)</h4>
                                                    <div>
                                                        {!txnId ? (
                                                            <form onSubmit={handleAbhaSubmit}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >ABHA Address <span className="login-danger">*</span></label>
                                                                        <input
                                                                            fullWidth
                                                                            className="form-control"
                                                                            value={abhaSegments}
                                                                            onChange={handleAbhaChange}
                                                                            type="text" />

                                                                    </Grid>

                                                                </Grid>

                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleAbhaSubmit}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form">Cancel</button>
                                                                </div>
                                                                {/* <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    className="btn btn-primary submit-form me-2"
                                                                >
                                                                    Request OTP
                                                                </Button> */}
                                                            </form>
                                                        ) : (
                                                            <form onSubmit={handleOtpSubmit}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >Mobile No. <span className="login-danger">*</span></label>

                                                                        <input
                                                                            className="form-control"
                                                                            label="Mobile Number"
                                                                            value={mobileNo}
                                                                            onChange={handleMobileChange}
                                                                            inputProps={{ maxLength: 10 }} // Limit to 10 digits
                                                                            inputRef={mobileRef}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <label >OTP <span className="login-danger">*</span></label>

                                                                        <input

                                                                            className="form-control"
                                                                            label="OTP"
                                                                            value={otp}
                                                                            onChange={(e) => setOtp(e.target.value)}
                                                                            inputProps={{ maxLength: 6 }} // Limit to 6 digits
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <div class="doctor-submit text-end">
                                                                    <button type="submit" className="btn btn-primary submit-form me-2" onClick={handleOtpSubmit}>Submit</button>
                                                                    <button type="cancel" className="btn btn-primary cancel-form">Cancel</button>
                                                                </div>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AbhaOtp