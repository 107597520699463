import { baseUrl } from './Aadhar';
export const GETAPI = async (path) => {
  try {
    const response = await fetch(`${baseUrl}${path}`, {
      method: "POST",
    });
    const data = await response.json();
    console.log(data);
    if (data && data.status === "200") {
      return data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

// export const POSTAPI = async (path, id) => {
//   const form = new FormData();
//   form.append("id", id);
//   try {
//     const response = await fetch(`${baseUrl}${path}`, {
//       method: "POST",
//       body: form,
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "text/plain",
//       },
//     });
//     const data = await response.json();
//     // if (data && data.status === "200") {
//     return data.data;
//     // } else {
//     //   return null;
//     // }
//   } catch (error) {
//     console.error(error);
//   }
// };
export const POSTAPI = async (path, id) => {
  const formData = new FormData();
  formData.append("id", id);

  try {
      const response = await fetch(`${baseUrl}${path}`, {
          method: "POST",
          body: formData,
          headers: {
              Accept: "application/json", // Expecting JSON response
          },
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.data; // Assuming data contains the JSON response object
  } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Handle errors or return null based on your application's logic
  }
};
