import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));
const ABHA = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { Abha } = location.state;

    // Abha 
    const [aadharNo, setAadharNo] = useState('');
    const [abhaId, setAbhaId] = useState('');
    const [abhaAddress, setAbhaAddress] = useState('');
    const [encryptedText, setEncryptedText] = useState('');
    const [decryptedImageSrc, setDecryptedImageSrc] = useState('');
    const handleDecrypt = () => {
        try {
          // Assuming Abha.photo is already a valid base64 string
          const imageSrc = `data:image/png;base64,${Abha.photo}`;
          setDecryptedImageSrc(imageSrc);
          console.log('Image',decryptedImageSrc)
        } catch (error) {
          setDecryptedImageSrc('');
          alert('Invalid base64 string');
        }
      };
    
      useEffect(() => {
        // setTimeout(() => {
          handleDecrypt();
        // }, 1000);
      }, []);
    return (
        <>
            <Layout>
                <Container maxWidth="lg">
                    {/* <pr e>{JSON.stringify(Abha, null, 2)}</pr> */}

                    <Card sx={{ width: 555, margin: 'auto', mt: 4 }}>
                        <div style={{ display: 'flex' }}>
                        {decryptedImageSrc && <img style={{ height: 140  , padding:'10px'}} src={decryptedImageSrc} alt="Decrypted" />}                      
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    ABHA Card
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>ID:</strong> {Abha.abhaNumber} <br />
                                    <strong>Name:</strong> {Abha.firstName} {Abha.lastName} <br />
                                    <strong>DOB:</strong> {Abha.dob} <br />
                                    <strong>Gender:</strong> {Abha.gender}<br/>
                                    <strong>ABHA address:</strong> {Abha.phrAddress}<br/>
                                    <strong>Mobile :</strong> {Abha.mobile}
                                </Typography>
                            </CardContent>
                        </div>
                        {/* <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button size="small" fullWidth variant="contained">Edit</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button size="small" fullWidth variant="outlined">Download</Button>
                                </Grid>
                            </Grid>
                        </CardActions> */}
                    </Card>
                </Container>
            </Layout>
        </>
    )
}

export default ABHA
