import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { Container, makeStyles, TextField, Typography, CircularProgress, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        position: 'relative',
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    warningMessage: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        color: 'red',
    },
    Message: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
        color: 'black',
    },
}));

const Scan = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [scannedData, setScannedData] = useState({
        hidn: '',
        hid: '',
        name: '',
        gender: '',
        statelgd: '',
        distlgd: '',
        dob: '',
        district_name: '',
        mobile: '',
        address: '',
        state_name: '',
    });
    const [loading, setLoading] = useState(false);
    const [errorShown, setErrorShown] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);
    const [loader, setLoader] = useState(true);
    const inputRef = useRef(null);

    const handleScanInput = (event) => {
        setLoader(false);
        const data = event.target.value;
        console.log('Scanned Data:', data);
        if (data) {
            setLoading(true);
            setErrorShown(false);
            try {
                const parsedData = JSON.parse(data);

                setScannedData(parsedData);
                const stateName = parsedData['state name'];

                setScannedData((prevState) => ({
                    ...prevState,
                    state_name: stateName,

                }));
                toast.success('Data scanned successfully!');
                event.target.value = '';
                setIsDataReady(true);
                setLoader(true);
            } catch (error) {
                if (!errorShown) {
                    // toast.error('Error parsing scanned data. Please ensure it is in JSON format.');
                    setErrorShown(true);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const input = inputRef.current;
        input.addEventListener('input', handleScanInput);
        input.focus();

        return () => {
            input.removeEventListener('input', handleScanInput);
        };
    }, [errorShown]);

    // Handle navigation attempts
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (loading) {
                event.preventDefault();
                event.returnValue = ''; // Necessary for some browsers to show a prompt
            }
        };

        const handlePopState = (event) => {
            if (loading) {
                toast.info('Please complete the scan process before navigating away.');
                navigate(0); // Reloads the current page to prevent navigation
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [loading, navigate]);

    return (
        <>
            <Layout />
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">ABHA</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">Scan ABHA Card</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>Scan ABHA Card</h4>
                                                    <div>
                                                        <label style={{ fontSize: '1.2em', marginBottom: '8px', display: 'block', color: '#007bff' }}>
                                                            ABHA Information
                                                        </label>
                                                        <input
                                                            ref={inputRef}
                                                            type="text"
                                                            className="form-control"
                                                            autoFocus

                                                        />

                                                        {!isDataReady && (
                                                            <Typography className={classes.Message}>
                                                                Use a barcode reader to scan the QR code on the Abha Card, and you'll instantly access all relevant information.
                                                            </Typography>
                                                        )}

                                                        {!loader && (
                                                            <>
                                                                <div className={classes.loader}>
                                                                    <CircularProgress />
                                                                </div>
                                                                <Typography className={classes.warningMessage}>
                                                                    If you switch pages or browsers, you'll have to begin from the beginning.
                                                                </Typography>
                                                            </>
                                                        )}

                                                        {isDataReady && (
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} md={4}>
                                                                <label >HIDN<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="HIDN"
                                                                      className="form-control"
                                                                        fullWidth
                                                                        name="hidn"
                                                                        disabled
                                                                        value={scannedData.hidn}
                                                                       
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >HID<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="HID"
                                                                       className="form-control"
                                                                        fullWidth
                                                                        name="hid"
                                                                        value={scannedData.hid}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >Name <span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="Name"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="name"
                                                                        value={scannedData.name}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >Gender<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="Gender"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="gender"
                                                                        value={scannedData.gender}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                               
                                                               
                                                                <Grid item xs={6} md={4}>
                                                                <label >DOB <span className="login-danger">*</span></label>
                                                                       
                                                                    <input

                                                                        label="DOB"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="dob"
                                                                        value={scannedData.dob}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >District Name<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="District Name"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="district_name"
                                                                        value={scannedData.district_name}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >Mobile<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="Mobile"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="mobile"
                                                                        value={scannedData.mobile}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >Address <span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="Address"
                                                                         className="form-control"
                                                                        fullWidth
                                                                        multiline
                                                                        rows={3}
                                                                        name="address"
                                                                        value={scannedData.address}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                <label >State Name<span className="login-danger">*</span></label>
                                                                       
                                                                    <input
                                                                        label="State Name"
                                                                        className="form-control"
                                                                        fullWidth
                                                                        name="state_name"
                                                                        value={scannedData.state_name}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Scan;
