import { Button } from '@material-ui/core'
import React from 'react'
import DocumentTitle from 'react-document-title'

const Error = () => {
    return (
        <>
            <DocumentTitle title="ERROR || FLUX">
                <div className="error-pages">
                    <div className="main-wrapper error-wrapper">
                        <div className="error-box">
                            <img className="img-fluid" src="assets/img/error-01.png" alt="Logo" />
                            <div>
                                <span className='myH3'><img className="img-fluid mb-0" src="assets/img/icon/danger.svg" alt="Logo" />   <span className='myheading'>Service Unavailable</span></span>

                            </div>
                            <p>You may have mistyped the address or the page may have moved.</p>
                            {/* <Button variant="contained">Back to Home </Button> */}
                            <a href="/home" className="btn btn-primary go-home">Back to Home</a>
                        </div>
                    </div>


                </div>
            </DocumentTitle>
        </>
    )
}

export default Error
