// import React, { useEffect, useState } from 'react'
// import { GETAPI, POSTAPI } from '../Redux/Slice/Address';

// import Layout from '../Layout/Layout';
// import { Button, Checkbox, Container, Dialog, DialogActions, Box, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
// import {
//     MaterialReactTable,
//     createMRTColumnHelper,
//     useMaterialReactTable,
// } from "material-react-table";
// import { mkConfig, generateCsv } from "export-to-csv";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import jsPDF from 'jspdf';
// import { format } from "date-fns";
// import autoTable from "jspdf-autotable";
// import EditIcon from '@mui/icons-material/Edit';
// import { Info } from '@material-ui/icons';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import { Link, useNavigate } from 'react-router-dom';
// import { FaFileExcel } from "react-icons/fa";
// import { useDispatch } from 'react-redux';
// const useStyles = makeStyles((theme) => ({
//     formContainer: {
//         padding: theme.spacing(4),
//         backgroundColor: '#f5f5f5',
//         borderRadius: '8px',
//     },
//     headerContainer: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         marginBottom: theme.spacing(2),
//     },
//     formField: {
//         margin: theme.spacing(2, 0),
//     },
//     submitButton: {
//         marginTop: theme.spacing(4),
//         backgroundColor: '#007bff',
//         color: '#fff',
//         '&:hover': {
//             backgroundColor: '#0056b3',
//         },
//     },
//     formTitle: {
//         marginBottom: theme.spacing(2),
//         color: '#3f51b5',
//     },
// }));
// const List = () => {
//     const classes = useStyles();
//     const [Country, setCountry] = useState([]);
//     const getView = async () => {
//         try {
//             const result = await GETAPI("GetAllEnrollPatient");
//             if (result) {
//                 setCountry(result);
//                 setLoading(false)
//             }

//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };

//     useEffect(() => {

//         getView();
//     }, []);



//     //---------- TABLE ----------
//     const dispatch = useDispatch();
//     const [open, setOpen] = useState(false);
//     const [selectedColumns, setSelectedColumns] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();
//     const [openD, setOpenD] = useState(false);
//     const [patientDetails, setPatientDetails] = useState([]);
//     // Details 
//     const handleDetailsClick = async (id) => {
//         setOpenD(true);
//         try {
//             const result1 = await POSTAPI("GetAllEnrollPatientById", id);

//             setPatientDetails(result1);
//             console.log("patientDetails", result1)

//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }

//     };


//     const handleClose = () => {
//         setOpenD(false);
//         setPatientDetails(null);
//     };
//     const handleEditClick = (id) => {
//         // You can perform any additional actions here, like fetching the data by ID
//         navigate(`/edit-patient/${id}`);
//     };

//     const handleExportButtonClick = () => {
//         setOpen(true);
//     };

//     const handleClose1 = () => {
//         setOpen(false);
//     };
//     const handleToggleColumn = (columnName) => {
//         const currentIndex = selectedColumns.indexOf(columnName);
//         const newSelectedColumns = [...selectedColumns];

//         if (currentIndex === -1) {
//             newSelectedColumns.push(columnName);
//         } else {
//             newSelectedColumns.splice(currentIndex, 1);
//         }

//         setSelectedColumns(newSelectedColumns);
//     };
//     const columnHelper = createMRTColumnHelper();
//     const columns = [
//         columnHelper.accessor("name", {
//             enableColumnFilter: false,
//             enableColumnActions: false,
//             enableSorting: false,
//             header: "Action",
//             Cell: (row) => {
//                 return (
//                     <>
//                         <div style={{ display: "flex", gap: "15px" }}>

//                             <span
//                                 type="button"
//                                 data-toggle="modal"
//                                 data-target="#exampleModalLongEdit"
//                                 onClick={() => handleEditClick(row.row.original.Id)}
//                             >
//                                 <EditIcon />
//                             </span>

//                             <span
//                                 type="button"
//                                 data-toggle="modal"
//                                 data-target="#exampleModalLongDetail"
//                                 onClick={() => handleDetailsClick(row.row.original.Id)}
//                             >
//                                 <Info />
//                             </span>

//                             {/* <span>
//                                 <a
//                                     style={{
//                                         cursor: "pointer",
//                                         // display: isStatus === "0" ? "none" : "block",
//                                     }}
//                                 // onClick={() => deActive(row.row.original)}
//                                 >
//                                     {row.row.original.Status === "1" ? (
//                                         <img
//                                             className="thumb_icon"
//                                             src="assets/img/icons/Up.svg"
//                                             title="Status"
//                                         />
//                                     ) : (
//                                         <img
//                                             className="thumb_icon"
//                                             src="assets/img/icons/Down.svg"
//                                             title="Status"
//                                         />
//                                     )}
//                                 </a>
//                             </span>          {/* {isDelete !== "0" && ( */}
//                             {/* <span
//                                 data-bs-toggle="modal"
//                                 data-bs-target="#exampleModal"
//                                 style={{ cursor: "pointer" }} 
//                              onClick={() => setDelId(row.row.original.Id)}
//                             >
//                                 <img
//                                     src="assets/img/icons/delete.svg"
//                                     alt="Delete"
//                                     title="Delete"
//                                 />
//                             </span> */}
//                             {/* )} */}
//                         </div>
//                     </>
//                 );
//             },
//         }),

//         columnHelper.accessor("Unit", {
//             header: "Unit",
//             Cell: (row) => {
//                 return (
//                     <div>
//                         {row.row.original.Unit === null || row.row.original.Unit === ""
//                             ? "-"
//                             : row.row.original.Unit}
//                     </div>
//                     // <div>
//                     //     {row.row.original.Date === null ||
//                     //         row.row.original.Date === "0000-00-00"
//                     //         ? "-"
//                     //         : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
//                     // </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("Name", {
//             header: "Name",
//             Cell: (row) => {
//                 return (
//                     <div >

//                         {row.row.original.Name}

//                     </div>
//                 );
//             },
//         }),

//         columnHelper.accessor("Gender", {
//             header: "Gender",
//             Cell: (row) => {
//                 return (
//                     <div>
//                       {row.row.original.Gender === 'F' ? 'Female' : row.row.original.Gender === 'M' ? 'Male' : '-'}

//                     </div>
//                 );
//             },
//         }),

//         columnHelper.accessor("MobileNo", {
//             header: "Mobile No",
//             Cell: (row) => {
//                 return (
//                     <div >

//                         {row.row.original.MobileNo}

//                     </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("AbhaId", {
//             header: "Abha Id",
//             Cell: (row) => {
//                 return (
//                     <div>
//                         {row.row.original.AbhaId}
//                     </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("AbhaAddress", {
//             header: "ABHA Address",
//             Cell: (row) => {
//                 return (
//                     <div>

//                         {row.row.original.AbhaAddress}
//                     </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("AadharNo", {
//             header: "Aadhar No",
//             Cell: (row) => {
//                 return (
//                     <div>
//                         {row.row.original.AadharNo}
//                     </div>
//                 );
//             },
//         }),

//         columnHelper.accessor("Dob", {
//             header: "Date of Birth",
//             Cell: (row) => {
//                 return (

//                     <div>
//                         {row.row.original.Dob === null ||
//                             row.row.original.Dob === "0000-00-00"
//                             ? "-"
//                             : new Date(row.row.original.Dob).toLocaleDateString("en-GB")}
//                     </div>

//                 );
//             },
//         }),

//         columnHelper.accessor(row => `${row.ResAddress} ${row.ResCountry} ${row.ResState} ${row.ResDistrict}${row.ResTaluka} ${row.ResTown}${row.ResAreaCode}`, {
//             header: "Residential Address",
//             cell: info => (
//                 <div>
//                     {info.getValue() || "-"}
//                 </div>
//             ),
//         }),

//         columnHelper.accessor(row => `${row.PerAddress} ${row.PerCountry} ${row.PesState} ${row.PerDistrict}${row.PerTaluka} ${row.PerTown}${row.PerAreaCode}`, {
//             header: "Permanent Address",
//             cell: info => (
//                 <div>
//                     {info.getValue() || "-"}
//                 </div>
//             ),
//         }),

//         columnHelper.accessor("ContactNo", {
//             header: "Contact No",
//             Cell: (row) => {
//                 return (
//                     <div>
//                         {row.row.original.ContactNo === '0' ? ' ' : row.row.original.ContactNo}
//                     </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("Email", {
//             header: "Email",
//             Cell: (row) => {
//                 return (
//                     <div>

//                         {row.row.original.Email}
//                     </div>
//                 );
//             },
//         }),
//         columnHelper.accessor("CreatedDate", {
//             header: "CreatedDate",
//             Cell: (row) => {
//                 return (
//                     <div>
//                         {row.row.original.CreatedDate === null ||
//                             row.row.original.CreatedDate === "0000-00-00"
//                             ? "-"
//                             : new Date(row.row.original.CreatedDate).toLocaleDateString("en-GB")}
//                     </div>
//                 );
//             },
//         }),
//     ];

//     const csvConfig = mkConfig({
//         fieldSeparator: ",",
//         decimalSeparator: ".",
//         useKeysAsHeaders: true,
//     });

//     const excelData =
//         Country &&
//         Country.map((obj) => {
//             const { Id, Active, UpdatedBy, UpdatedDate, CreatedBy, CreatedDate, code, message, Status, AdminID, ...rest } = obj;
//             return rest;
//         });

//     const handleExportData = () => {
//         const csvConfig = mkConfig({
//             fieldSeparator: ",",
//             decimalSeparator: ".",
//             useKeysAsHeaders: true,
//         });

//         const formattedDate = new Date().toISOString().slice(0, 10);
//         const filename = `Patient_${formattedDate}.csv`;

//         const csv = generateCsv(csvConfig)(excelData);
//         const blob = new Blob([csv], { type: "text/csv" });
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = filename;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };

//     const handleExportRows = (rows) => {
//         const doc = new jsPDF();
//         const columnsToExport = [
//             { id: "serialNo", header: "S No." },
//             ...columns.filter((column) => selectedColumns.includes(column.id)),
//         ];
//         console.log(selectedColumns)

//         const tableHeaders = columnsToExport.map((c) => c.header);

//         const formattedDate = new Date().toLocaleDateString("en-GB", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//         });

//         const filename = `Patients | FLUX_${formattedDate}.pdf`;
//         const addWatermarkToFirstPage = () => {
//             const watermarkHeight = 20;

//             doc.setFontSize(10);
//             doc.text(
//                 10,
//                 watermarkHeight + -10,
//                 `Generated by : ${localStorage.getItem("name")} `
//             );
//             doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
//             doc.setFontSize(16);
//             doc.text("Patient Table", 84, 20);
//         };
//         const watermarkHeight = 20;

//         addWatermarkToFirstPage();
//         const tableData = rows.map((row, index) => {
//             const formattedDateInPdf = row.original["Date"]
//                 ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
//                 : "";

//             return [
//                 { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
//                 ...columnsToExport.slice(1).map((column) => {
//                     // Exclude the first column (serial number) when mapping
//                     if (column.id === "Date") {
//                         return formattedDateInPdf;
//                     }

//                     const value =
//                         column.id in row.original ? row.original[column.id] : "";
//                     return value !== null && value !== undefined ? value : "";
//                 }),
//             ];
//         });
//         autoTable(doc, {
//             head: [tableHeaders],
//             body: tableData,
//             startY: watermarkHeight + 5,
//         });

//         // Add a heading to the table

//         // Save the PDF to a Blob
//         const pdfBlob = doc.output("blob");

//         // Create a blob URL
//         const blobUrl = URL.createObjectURL(pdfBlob);

//         // Open the URL in a new tab
//         window.open(blobUrl, "_blank");

//         // Optionally revoke the object URL after opening the new tab
//         URL.revokeObjectURL(blobUrl);
//         // doc.save(filename);
//     };

//     const table = useMaterialReactTable({
//         columns,
//         data: Country && Country,
//         enableRowSelection: false,
//         enableColumnPinning: true,
//         columnFilterDisplayMode: "popover",
//         initialState: {
//             columnVisibility: {
//                 Status: false,
//                 AddedBy: false,
//                 UpdatedBy: false,
//                 UpdatedDate: false,
//                 CreatedDate: false,
//             },
//         },
//         enableStickyHeader: true,
//         enableRowNumbers: true,
//         muiPaginationProps: {
//             rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
//             showFirstButton: true,
//             showLastButton: true,
//         },
//         state: {
//             isLoading: loading,
//         },
//         muiCircularProgressProps: {
//             color: "primary",
//             thickness: 5,
//             size: 55,
//             style: {
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 zIndex: 1000,
//             },
//         },
//         muiSkeletonProps: {
//             animation: "pulse",
//             height: 20,
//         },
//         positionToolbarAlertBanner: "bottom",
//         renderTopToolbarCustomActions: ({ table }) => (
//             <Box
//                 sx={{
//                     display: "flex",
//                     gap: "16px",
//                     padding: "8px",
//                     flexWrap: "wrap",
//                 }}
//             >
//                 <Button style={{ 'color': '#3f51b5', 'fontSize': '19px' }}
//                     disabled={table.getPrePaginationRowModel().rows.length === 0}
//                     onClick={handleExportData}
//                     startIcon={<FileDownloadIcon />}
//                 >
//                     <FaFileExcel />
//                 </Button>

//                 <Button style={{ 'color': '#3f51b5', 'fontSize': '19px' }}
//                     disabled={
//                         !table || table.getPrePaginationRowModel().rows.length === 0
//                     }
//                     onClick={handleExportButtonClick}
//                     startIcon={<FileDownloadIcon />}
//                 >
//                     <PictureAsPdfIcon />
//                 </Button>
//                 <Dialog open={open} onClose={handleClose1}>
//                     <DialogTitle>Select Columns To Export</DialogTitle>
//                     <DialogContent>
//                         {columns.map((column) =>
//                             column.header !== "Action" ? (
//                                 <div key={column.id}>
//                                     <Checkbox
//                                         checked={selectedColumns.includes(column.id)}
//                                         onChange={() => handleToggleColumn(column.id)}
//                                     />
//                                     {column.header}
//                                 </div>
//                             ) : (
//                                 ""
//                             )
//                         )}
//                     </DialogContent>
//                     <DialogActions>
//                         <Button onClick={handleClose1}>Cancel</Button>
//                         <Button
//                             onClick={() =>
//                                 handleExportRows(table.getPrePaginationRowModel().rows)
//                             }
//                         >
//                             Export
//                         </Button>
//                     </DialogActions>
//                 </Dialog>
//             </Box>
//         ),
//     });

//     return (
//         <>
//             <Layout>
//                 <Container maxWidth="lg">

//                     <div className={classes.headerContainer}>
//                         <Typography variant="h4" className={classes.formTitle}>
//                             Patient List
//                         </Typography>
//                         <Link to='/add-patient'>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 className={classes.submitButton}
//                             >
//                                 Add Patient
//                             </Button>
//                         </Link>
//                     </div>

//                     <MaterialReactTable table={table} />
//                 </Container>
//                 <Dialog open={openD} onClose={handleClose}>
//                     <DialogTitle>Patient Details</DialogTitle>
//                     <DialogContent>
//                         {patientDetails ? (
//                             <div className='detailsDiv'>
//                                 <div className='detailsRow'>
//                                     <p><strong>Unit:</strong> {patientDetails.unit}</p>
//                                     <p><strong>Name:</strong> {`${patientDetails.firstName} ${patientDetails.middleName} ${patientDetails.lastName}`}</p>
//                                     <p><strong>Age:</strong> {patientDetails.age}</p>
//                                     <p><strong>Gender:</strong> {patientDetails.gender}</p>
//                                     <p><strong>Mobile No:</strong> {patientDetails.mobileNo}</p>
//                                     <p><strong>Email:</strong> {patientDetails.email}</p>
//                                     <p><strong>Date of Birth:</strong> {new Date(patientDetails.dob).toLocaleDateString()}</p>
//                                     <p><strong>Residential Address:</strong> {patientDetails.resAddress}</p>
//                                     <p><strong>Residential Country:</strong> {patientDetails.resCountry}</p>
//                                     <p><strong>Residential State:</strong> {patientDetails.resState}</p>
//                                     <p><strong>Residential District:</strong> {patientDetails.resDistrict}</p>
//                                     <p><strong>Residential Taluka:</strong> {patientDetails.resTaluka}</p>
//                                     <p><strong>Residential Town:</strong> {patientDetails.resTown}</p>
//                                     <p><strong>Residential Area Code:</strong> {patientDetails.resAreaCode}</p>
//                                     <p><strong>Permanent Address:</strong> {patientDetails.perAddress}</p>
//                                     <p><strong>Permanent Country:</strong> {patientDetails.perCountry}</p>
//                                     <p><strong>Permanent State:</strong> {patientDetails.pesState}</p>
//                                     <p><strong>Permanent District:</strong> {patientDetails.perDistrict}</p>
//                                     <p><strong>Permanent Taluka:</strong> {patientDetails.perTaluka}</p>
//                                     <p><strong>Permanent Town:</strong> {patientDetails.perTown}</p>
//                                     <p><strong>Permanent Area Code:</strong> {patientDetails.perAreaCode}</p>
//                                     <p><strong>Aadhar No:</strong> {patientDetails.aadharNo}</p>
//                                     <p><strong>Contact No:</strong> {patientDetails.contactNo}</p>
//                                     <p><strong>ABHA ID:</strong> {patientDetails.abhaId}</p>
//                                     <p><strong>ABHA Address:</strong> {patientDetails.abhaAddress}</p>
//                                 </div>
//                             </div>
//                         ) : (
//                             <p>Loading...</p>
//                         )}
//                     </DialogContent>
//                     <DialogActions>
//                         <Button onClick={handleClose} color="primary">
//                             Close
//                         </Button>
//                     </DialogActions>
//                 </Dialog>
//             </Layout>

//         </>
//     )
// }

// export default List






import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Button, Checkbox, Container, Dialog, DialogActions, Box, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { GETAPI, POSTAPI } from '../Redux/Slice/Address';
import { useNavigate } from 'react-router-dom';
import { mkConfig, generateCsv } from "export-to-csv";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import autoTable from "jspdf-autotable";
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import jsPDF from 'jspdf';
import { FaFileExcel } from "react-icons/fa";
import { format } from "date-fns";
import * as XLSX from 'xlsx';

const List = () => {
    const [Country, setCountry] = useState([]);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [openD, setOpenD] = useState(false);
    const [patientDetails, setPatientDetails] = useState([]);

    const handleToggleColumn = (columnName) => {
        const currentIndex = selectedColumns.indexOf(columnName);
        const newSelectedColumns = [...selectedColumns];

        if (currentIndex === -1) {
            newSelectedColumns.push(columnName);
        } else {
            newSelectedColumns.splice(currentIndex, 1);
        }

        setSelectedColumns(newSelectedColumns);
    };

    const handleExportButtonClick = () => {
        setOpen(true);
    };

    const csvConfig = mkConfig({
        fieldSeparator: ",",
        decimalSeparator: ".",
        useKeysAsHeaders: true,
    });

    const excelData =
        Country &&
        Country.map((obj) => {
            const { Id, Active, UpdatedBy, UpdatedDate, CreatedBy, CreatedDate, code, message, Status, AdminID, ...rest } = obj;
            return rest;
        });

    // const handleExportData = () => {
    //     const csvConfig = mkConfig({
    //         fieldSeparator: ",",
    //         decimalSeparator: ".",
    //         useKeysAsHeaders: true,
    //     });

    //     const formattedDate = new Date().toISOString().slice(0, 10);
    //     const filename = `Patient_${formattedDate}.csv`;

    //     const csv = generateCsv(csvConfig)(excelData);
    //     const blob = new Blob([csv], { type: "text/csv" });
    //     const link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = filename;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };


    const handleExportData = () => {
        // Step 1: Format the current date and prepare the filename
        const formattedDate = new Date().toISOString().slice(0, 10); // Date in YYYY-MM-DD format
        const fullName = localStorage.getItem("FullName"); // Assuming user full name is stored in localStorage

        // Add a fallback if fullName is null or undefined
        const formattedFullName = fullName ? fullName.charAt(0).toUpperCase() + fullName.slice(1) : 'Unknown_User';

        const filename = `Patient_List_Flux_${formattedFullName}_${formattedDate}.xlsx`;

        // Step 2: Prepare the data for the Excel sheet
        const excelData = Country?.map((obj, index) => {
            const { Id, Active, UpdatedBy, UpdatedDate, CreatedBy, CreatedDate, code, message, Status, AdminID, ...rest } = obj;

            // Add serial number (SNo) to each row and return a new object
            return { SNo: index + 1, ...rest }; // SNo is the serial number (index + 1)
        }) || [];

        // Step 3: Create a new workbook and worksheet using the `xlsx` library
        const wb = XLSX.utils.book_new(); // Create a new workbook
        const ws = XLSX.utils.json_to_sheet([]); // Initialize a new worksheet

        // Step 4: Add a custom title/header to the Excel sheet (this will be merged across columns)
        const headerTitle = [['PATIENT LIST']]; // Title for the sheet
        XLSX.utils.sheet_add_aoa(ws, headerTitle, { origin: 'A1' });

        // Merge all cells in the first row to create a single centered header
        ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: Object.keys(excelData[0] || {}).length - 1 } }];

        // Style the merged header cell to center the text horizontally and vertically
        ws['A1'].s = {
            alignment: {
                horizontal: 'center',
                vertical: 'center',
            },
            font: {
                bold: true,  // Make the title bold
                sz: 14,      // Font size
            },
        };

        // Step 5: Add the headers in the second row, capitalize the headers
        const headers = Object.keys(excelData[0] || {}).map(key => key.toUpperCase()); // Capitalize headers
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A2' });

        // Step 6: Add the actual data starting from the third row
        XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A3', skipHeader: true });

        // Step 7: Set the column width for each column to ensure all data fits
        const columnWidths = headers.map(() => ({ wch: 20 })); // Set width for each column (can adjust as needed)
        ws['!cols'] = columnWidths;

        // Step 8: Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Patient List');

        // Step 9: Generate the Excel file and trigger download
        XLSX.writeFile(wb, filename); // Download the file with the prepared filename
    };


    const handleExportRows = (rows) => {
        const doc = new jsPDF();
        const columnsToExport = [
            { id: "serialNo", header: "S No." },
            ...columns.filter((column) => selectedColumns.includes(column.id)),
        ];
        console.log(selectedColumns)

        const tableHeaders = columnsToExport.map((c) => c.header);

        const formattedDate = new Date().toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        const filename = `Patients | FLUX_${formattedDate}.pdf`;
        const addWatermarkToFirstPage = () => {
            const watermarkHeight = 20;

            doc.setFontSize(10);
            doc.text(
                10,
                watermarkHeight + -10,
                `Generated by : ${localStorage.getItem("name")} `
            );
            doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
            doc.setFontSize(16);
            doc.text("Patient Table", 84, 20);
        };
        const watermarkHeight = 20;

        addWatermarkToFirstPage();
        const tableData = rows.map((row, index) => {
            const formattedDateInPdf = row.original["Date"]
                ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
                : "";

            return [
                { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
                ...columnsToExport.slice(1).map((column) => {
                    // Exclude the first column (serial number) when mapping
                    if (column.id === "Date") {
                        return formattedDateInPdf;
                    }

                    const value =
                        column.id in row.original ? row.original[column.id] : "";
                    return value !== null && value !== undefined ? value : "";
                }),
            ];
        });
        autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
            startY: watermarkHeight + 5,
        });

        // Add a heading to the table

        // Save the PDF to a Blob
        const pdfBlob = doc.output("blob");

        // Create a blob URL
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Open the URL in a new tab
        window.open(blobUrl, "_blank");

        // Optionally revoke the object URL after opening the new tab
        URL.revokeObjectURL(blobUrl);
        // doc.save(filename);
    };

    // Details 

    const handleDetailsClick = async (id) => {
        setOpenD(true);
        try {
            const result1 = await POSTAPI("GetAllEnrollPatientById", id);

            setPatientDetails(result1);
            console.log("patientDetails", result1)

        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };


    const getView = async () => {
        try {
            const result = await GETAPI("GetAllEnrollPatient");
            if (result) {
                setCountry(result);
                setLoading(false)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getView();
    }, []);

    const handleClose1 = () => {
        setOpen(false);
    };
    const handleClose = () => {
        setOpenD(false);
        setPatientDetails(null);
    };
    const handleEditClick = (id) => {
        navigate(`/edit-patient/${id}`);
    };

    const columns = [
        {
            header: 'Actions',  // This column will hold the action buttons
            id: 'actions',     // Give the column a unique ID
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '15px' }}>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEditClick(row.original.Id)}
                    >
                        <EditIcon />
                    </span>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDetailsClick(row.original.Id)}
                    >
                        <InfoIcon />
                    </span>
                    {/* <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => setDelId(row.row.original.Id)}
                    >
                        <DeleteIcon />
                    </span> */}
                </div>
            )
        },
        { header: 'Unit', accessorKey: 'Unit' },
        { header: 'Prefix', accessorKey: 'Prefix' },
        { header: 'First Name', accessorKey: 'FirstName' },
        { header: 'Middle Name', accessorKey: 'MiddleName' },
        { header: 'Last Name', accessorKey: 'LastName' },
        { header: 'Full Name', accessorKey: 'Name' },
        { header: 'Gender', accessorKey: 'Gender' },
        { header: 'Mobile Number', accessorKey: 'MobileNo' },
        { header: 'Email', accessorKey: 'Email' },
        {
            header: 'Date of Birth',
            accessorKey: 'Dob',  // Make sure this matches the field name in your data
            Cell: ({ row }) => {
                // Access the `Dob` field directly from `row.original`
                const date = new Date(row.original.Dob);
                return date.toLocaleDateString("en-GB");  // Format the date as DD/MM/YYYY
            }
        },

        { header: 'Residential Address', accessorKey: 'ResAddress' },
        { header: 'Residential Country', accessorKey: 'ResCountry' },
        { header: 'Residential State', accessorKey: 'ResState' },
        { header: 'Residential District', accessorKey: 'ResDistrict' },
        { header: 'Residential Taluka', accessorKey: 'ResTaluka' },
        { header: 'Residential Town', accessorKey: 'ResTown' },
        { header: 'Residential Area Code', accessorKey: 'ResAreaCode' },
        { header: 'Permanent Address', accessorKey: 'PerAddress' },
        { header: 'Permanent Country', accessorKey: 'PerCountry' },
        { header: 'Permanent State', accessorKey: 'PesState' },
        { header: 'Permanent District', accessorKey: 'PerDistrict' },
        { header: 'Permanent Taluka', accessorKey: 'PerTaluka' },
        { header: 'Permanent Town', accessorKey: 'PerTown' },
        { header: 'Permanent Area Code', accessorKey: 'PerAreaCode' },
        { header: 'Aadhar Number', accessorKey: 'AadharNo' },
        { header: 'Contact Number', accessorKey: 'ContactNo' },
        { header: 'ABHA ID', accessorKey: 'AbhaId' },
        { header: 'ABHA Address', accessorKey: 'AbhaAddress' },
        { header: 'Created By', accessorKey: 'CreatedBy' },
        { header: 'Created Date', accessorKey: 'CreatedDate', Cell: ({ value }) => new Date(value).toLocaleString() }
    ];



    const table = useMaterialReactTable({
        columns,
        data: Country && Country,
        enableRowSelection: false,
        enableColumnPinning: true,
        columnFilterDisplayMode: "popover",
        initialState: {
            columnVisibility: {
                Status: false,
                AddedBy: false,
                UpdatedBy: false,
                UpdatedDate: false,
                CreatedDate: false,
            },
        },
        enableStickyHeader: true,
        enableRowNumbers: true,
        muiPaginationProps: {
            rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
            showFirstButton: true,
            showLastButton: true,
        },
        state: {
            isLoading: loading,
        },
        muiCircularProgressProps: {
            color: "primary",
            thickness: 5,
            size: 55,
            style: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
            },
        },
        muiSkeletonProps: {
            animation: "pulse",
            height: 20,
        },
        positionToolbarAlertBanner: "bottom",
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Button style={{ 'color': '#3f51b5', 'fontSize': '19px' }}
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    onClick={handleExportData}
                // startIcon={<FileDownloadIcon />}
                >
                    <FaFileExcel />
                </Button>

                <Button style={{ 'color': '#3f51b5', 'fontSize': '19px' }}
                    disabled={
                        !table || table.getPrePaginationRowModel().rows.length === 0
                    }
                    onClick={handleExportButtonClick}
                // startIcon={<FileDownloadIcon />}
                >
                    <PictureAsPdfIcon />
                </Button>
                <Dialog open={open} onClose={handleClose1}>
                    <DialogTitle>Select Columns To Export</DialogTitle>
                    <DialogContent>
                        {columns.map((column) =>
                            column.header !== "Action" ? (
                                <div key={column.id}>
                                    <Checkbox
                                        checked={selectedColumns.includes(column.id)}
                                        onChange={() => handleToggleColumn(column.id)}
                                    />
                                    {column.header}
                                </div>
                            ) : (
                                ""
                            )
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1}>Cancel</Button>
                        <Button
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                        >
                            Export
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        ),
    });
    return (
        <>
            <Layout />

            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="staff-list.html">Patient </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right" />
                                    </li>
                                    <li className="breadcrumb-item active">List Patients</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-heading">
                                                    <h4>Patients List</h4>
                                                    <MaterialReactTable table={table} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default List