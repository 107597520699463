import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Typography, Paper, MenuItem, Divider, Box, FormControlLabel, Checkbox, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Layout from './Layout/Layout';
import { GETAPI, POSTAPI } from './Redux/Slice/Address';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BookPosts } from './Redux/Slice/Registration';
import { useTheme } from '@emotion/react';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: 400,
    },
    tab: {
        color: 'white',
        width: 'maxContent',
    },
    tabPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },

}));

const Form = () => {
    const classes = useStyles();
    const theme = useTheme();
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const [value, setValue] = useState(0);

    const handleChange = (index) => {
        setValue(index);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    // form state
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [unit, setUnit] = useState('');
    const [prefixData, setPrefixData] = useState([]);
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [resAddress, setResAddress] = useState('');
    const [resCountry, setResCountry] = useState('');
    const [resState, setResState] = useState('');
    const [resDistrict, setResDistrict] = useState('');
    const [resTaluka, setResTaluka] = useState('');
    const [resTown, setResTown] = useState('');
    const [resAreaCode, setResAreaCode] = useState('');
    const [perAddress, setPerAddress] = useState('');
    const [perCountry, setPerCountry] = useState('');
    const [perState, setPerState] = useState('');
    const [perDistrict, setPerDistrict] = useState('');
    const [perTaluka, setPerTaluka] = useState('');
    const [perTown, setPerTown] = useState('');
    const [perAreaCode, setPerAreaCode] = useState('');
    const [aadharNo, setAadharNo] = useState('');
    const [abhaId, setAbhaId] = useState('');
    const [abhaAddress, setAbhaAddress] = useState('');
    const [sameAddress, setSameAddress] = useState(false);
    const [errors, setErrors] = useState({});
    const handleCheckboxChange = (e) => {
        setSameAddress(e.target.checked);
        if (e.target.checked) {
            setResAddress(perAddress);
            setResCountry(perCountry);
            setResState(perState);
            setResDistrict(perDistrict);
            setResTaluka(perTaluka);
            setResTown(perTown);
            setResAreaCode(perAreaCode);
        }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resetForm = () => {
        setFirstName('');
        setUnit('');
        setPrefix('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setMobileNo('');
        setContactNo('');
        setEmail('');
        setDob('');
        setResAddress('');
        setResCountry('');
        setResState('');
        setResDistrict('');
        setResTaluka('');
        setResTown('');
        setResAreaCode('');
        setPerAddress('');
        setPerCountry('');
        setPerState('');
        setPerDistrict('');
        setPerTaluka('');
        setPerTown('');
        setPerAreaCode('');
        setAadharNo('');
        setAbhaId('');
        setAbhaAddress('');
        setSameAddress(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('Id', '0');
        formData.append('Unit', '2');
        formData.append('Prefix', prefix);
        formData.append('FirstName', firstName);
        formData.append('MiddleName', middleName);
        formData.append('LastName', lastName);
        formData.append('Gender', gender);
        formData.append('MobileNo', mobileNo);
        formData.append('ContactNo', contactNo);
        formData.append('Email', email);
        formData.append('Dob', dob);
        formData.append('ResAddress', resAddress);
        formData.append('ResCountry', resCountry);
        formData.append('ResState', resState);
        formData.append('ResDistrict', resDistrict);
        formData.append('ResTaluka', 0);
        formData.append('ResTown', 0);
        formData.append('ResAreaCode', resAreaCode);
        formData.append('PerAddress', perAddress);
        formData.append('PerCountry', perCountry);
        formData.append('PesState', perState);
        formData.append('PerDistrict', perDistrict);
        formData.append('PerTaluka', 0);
        formData.append('PerTown', 0);
        formData.append('PerAreaCode', perAreaCode);
        formData.append('AadharNo', aadharNo);
        formData.append('AbhaId', abhaId);
        formData.append('AbhaAddress', abhaAddress);
        const response = await dispatch(BookPosts(formData));
        console.log(response.payload.success)

        if (response.payload.success === true && response.payload.message === "Patient added successfully.") {
            toast.success("Patient Added successfully");
            resetForm();
        }

    };
    // state,city,district
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [District, setDistrict] = useState([]);
    const [Taluka, setTaluka] = useState([]);
    const [Town, setTown] = useState([]);
    // Permanent
    const getState = async (id) => {
        setPerCountry(id);
        try {
            const result1 = await POSTAPI("State", id);
            if (result1) {
                setState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getDistrict = async (id) => {
        setPerState(id);
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getTaluka = async (id) => {
        setPerDistrict(id);
        try {
            const result1 = await POSTAPI("Taluka", id);
            if (result1) {
                setTaluka(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getTown = async (id) => {
        setPerTaluka(id)
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    // Residential
    const getRState = async (id) => {
        setResCountry(id);
        try {
            const result1 = await POSTAPI("State", id);
            if (result1.length > 0) {
                setState(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRDistrict = async (id) => {
        setResState(id);
        try {
            const result1 = await POSTAPI("District", id);
            if (result1) {
                setDistrict(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRTaluka = async (id) => {
        setResDistrict(id);
        try {
            const result1 = await POSTAPI("Taluka", id);
            if (result1) {
                setTaluka(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getRTown = async (id) => {
        setResTaluka(id)
        try {
            const result1 = await POSTAPI("Town", id);
            if (result1) {
                setTown(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getView = async () => {
        try {
            const result = await GETAPI("Country");
            if (result) {
                setCountry(result);
            }
            const result1 = await GETAPI("Prefix");
            if (result1) {
                setPrefixData(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {

        getView();
    }, []);
    return (
        <Layout>
            <Container maxWidth="lg">

                <Box sx={{ bgcolor: 'background.paper', width: 500 }}>

                    <>
                        <AppBar position="static">
                            <div>
                                <Tabs
                                    sx={{ maxWidth: 'max-content' }}
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="FRAME SIZE"

                                >

                                    <Tab sx={{

                                        color: 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            backgroundColor: 'rgb(17, 86, 159)', // Darker shade of #1976d2
                                        },
                                    }}
                                        // label={item.productName}
                                        {...a11yProps(0)}
                                        // key={item.categoryId} 
                                        className={classes.tab}
                                        label="Tab 1"
                                    />
                                    <Tab sx={{

                                        color: 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            backgroundColor: 'rgb(17, 86, 159)', // Darker shade of #1976d2
                                        },
                                    }}
                                        // label={item.productName}
                                        {...a11yProps(1)}
                                        // key={item.categoryId} 
                                        className={classes.tab}
                                        label="Tab 2"
                                    />
                                    <Tab sx={{
                                        color: 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            backgroundColor: 'rgb(17, 86, 159)', // Darker shade of #1976d2
                                        },
                                    }}
                                        // label={item.productName}
                                        {...a11yProps(2)}
                                        // key={item.categoryId} 
                                        className={classes.tab}
                                        label="Tab 3"
                                    />

                                </Tabs>
                            </div>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                Item One
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                Item Two
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                Item Three
                            </TabPanel>
                        </SwipeableViews>
                
                    </>

                </Box>
                {/* <Paper elevation={3} className={classes.formContainer}> */}
                <Typography variant="h4" className={classes.formTitle}>
                    Registration Form
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        {/* Personal Information */}
                        <Grid item xs={12} sm={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Unit"
                                name="unit"
                                value={unit}
                                onChange={(event) => {
                                    setUnit(event.target.value);
                                }}
                            >
                                <MenuItem value="1">Unit-1</MenuItem>
                                <MenuItem value="2">Unit-2</MenuItem>

                            </TextField>
                        </Grid><Grid item xs={12} sm={2}>    <TextField
                            variant="outlined"
                            fullWidth
                            select
                            label="Prefix"
                            name="Prefix"
                            value={prefix}
                            onChange={(event) => {
                                setPrefix(event.target.value);
                            }}
                        >
                            {prefixData.map(country => (
                                <MenuItem key={country.id} value={country.id}>
                                    {country.prefix}
                                </MenuItem>
                            ))}
                        </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Middle Name"
                                name="middleName"
                                value={middleName}
                                onChange={(event) => {
                                    setMiddleName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                value={lastName}
                                onChange={(event) => {
                                    setLastName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Gender"
                                name="gender"
                                value={gender}
                                onChange={(event) => {
                                    setGender(event.target.value);
                                }}
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Mobile Number"
                                value={mobileNo}
                                inputProps={{ maxLength: 10, minlength: 10 }}
                                helperText={errors.mobileNo ? errors.mobileNo : "Minimum 10 digits required"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setMobileNo(value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="date"
                                label="Date of Birth"
                                name="dob"
                                value={dob}
                                onChange={(event) => {
                                    setDob(event.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Contact Number"
                                value={contactNo}
                                helperText={errors.contactNo ? errors.contactNo : "Minimum 10 digits required"}
                                inputProps={{ maxLength: 10, minlength: 10 }}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setContactNo(value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {/* Permanent Address */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Permanent Address"
                                name="perAddress"
                                value={perAddress}
                                onChange={(event) => {
                                    setPerAddress(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Permanent Country"
                                name="perCountry"
                                value={perCountry}
                                onChange={(event) => {
                                    getState(event.target.value);
                                }}
                            >
                                {Country.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Permanent State"
                                name="perState"
                                value={perState}
                                onChange={(event) => {
                                    getDistrict(event.target.value);
                                }}
                            >
                                {State.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.stateName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Permanent District"
                                name="perDistrict"
                                value={perDistrict}
                                onChange={(event) => {
                                    getTaluka(event.target.value);
                                }}
                            >
                                {District.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.districtName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Permanent Taluka"
                                name="perTaluka"
                                value={perTaluka}
                                onChange={(event) => {
                                    getTown(event.target.value);
                                }}
                            >
                                {Taluka.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Permanent Town"
                                name="perTown"
                                value={perTown}
                                onChange={(event) => {
                                    setPerTown(event.target.value);
                                }}
                            >
                                {Town.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Permanent Area Code"
                                name="perAreaCode"
                                value={perAreaCode}
                                onChange={(event) => {
                                    setPerAreaCode(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sameAddress}
                                        onChange={handleCheckboxChange}
                                        name="sameAddress"
                                        color="primary"
                                    />
                                }
                                label="Residential address is the same as permanent address"
                            />
                        </Grid>
                        {/* Residential Address */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Residential Address"
                                name="resAddress"
                                value={resAddress}
                                onChange={(event) => {
                                    setResAddress(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Residential Country"
                                name="resCountry"
                                value={resCountry}
                                onChange={(event) => {
                                    getRState(event.target.value);
                                }}
                            >
                                {Country.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Residential State"
                                name="resState"
                                value={resState}
                                onChange={(event) => {
                                    getRDistrict(event.target.value);
                                }}
                            >
                                {State.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.stateName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Residential District"
                                name="resDistrict"
                                value={resDistrict}
                                onChange={(event) => {
                                    getRTaluka(event.target.value);
                                }}
                            >
                                {District.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.districtName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Residential Taluka"
                                name="resTaluka"
                                value={resTaluka}
                                onChange={(event) => {
                                    getRTown(event.target.value);
                                }}
                            >
                                {Taluka.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                label="Residential Town"
                                name="resTown"
                                value={resTown}
                                onChange={(event) => {
                                    setResTown(event.target.value);
                                }}
                            >
                                {Town.map(country => (
                                    <MenuItem key={country.id} value={country.id}>
                                        {country.countryName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Residential Area Code"
                                name="resAreaCode"
                                value={resAreaCode}
                                onChange={(event) => {
                                    setResAreaCode(event.target.value);
                                }}
                            />
                        </Grid>



                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        {/* Additional Information */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Aadhar Number"
                                name="aadharNo"
                                value={aadharNo}
                                inputProps={{ maxLength: 12, minlength: 12 }}
                                onChange={(event) => {
                                    setAadharNo(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ABHA ID"
                                name="abhaId"
                                value={abhaId}
                                inputProps={{ maxLength: 14, minlength: 14 }}
                                onChange={(event) => {
                                    setAbhaId(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ABHA Address"
                                name="abhaAddress"
                                value={abhaAddress}
                                onChange={(event) => {
                                    setAbhaAddress(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                    
                        type="submit"
                        variant="contained"
                        className={classes.submitButton}
                    >
                        Submit
                    </Button>
                </form>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        {/* Additional Information */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Aadhar Number"
                                name="aadharNo"
                                value={aadharNo}
                                inputProps={{ maxLength: 12, minlength: 12 }}
                                onChange={(event) => {
                                    setAadharNo(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ABHA ID"
                                name="abhaId"
                                value={abhaId}
                                inputProps={{ maxLength: 14, minlength: 14 }}
                                onChange={(event) => {
                                    setAbhaId(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ABHA Address"
                                name="abhaAddress"
                                value={abhaAddress}
                                onChange={(event) => {
                                    setAbhaAddress(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes.submitButton}
                    >
                        Submit
                    </Button>
                </form>
                {/* </Paper> */}
            </Container>
        </Layout>
    );
};

export default Form;
