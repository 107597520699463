import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Registration/Login";
import Home from "./Components/Home/Home";
import Form from "./Components/Forms/Form";
import Error from "./Components/Error";
import Test from "./Components/Test";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ABHA from "./Components/Forms/ABHA";
import List from "./Components/Lists/List";
import Edit from "./Components/Forms/Edit";
import CreateAbha from "./Components/Forms/CreateAbha";
import VerifyAbha from "./Components/Abha/VerifyAbha";
import AbhaOtp from "./Components/Abha/AbhaOtp";
import AadharOtp from "./Components/Abha/AadharOtp";
import Scan from "./Components/Abha/Scan";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/test" element={<Test />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/abdm" element={<ABHA />}></Route>
        <Route path="/patient" element={<List />}></Route>
        <Route path="/verify-aadhar-otp" element={<CreateAbha />}></Route>
        <Route path="/download-abha" element={<AadharOtp />}></Route>
        <Route path="/scan-abha" element={<Scan />}></Route>
        <Route path="/verify-abha" element={<VerifyAbha />}></Route>
        <Route path="/verify-abha-otp" element={<AbhaOtp />}></Route>
        <Route path="/edit-patient/:id" element={<Edit />}></Route>
        <Route path="*" element={<Error />}></Route>
        <Route path="/add-patient" element={<Form />}></Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
