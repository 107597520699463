// import React, { useState } from 'react';
// import { Container, Typography, TextField, Button, makeStyles } from '@material-ui/core';
// import { Link, useNavigate } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   form: {
//     maxWidth: '400px',
//     margin: '0 auto',
//     marginTop: theme.spacing(8),
//     padding: theme.spacing(4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     border: '1px solid #ccc',
//     borderRadius: theme.spacing(1),
//     boxShadow: theme.shadows[2],
//   },
//   textField: {
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(2),
//   },
// }));

// const Login = () => {
//   const classes = useStyles();
//   const [username, setUsername] = useState('admin@gmail.com');
//   const [password, setPassword] = useState('1234');
//   const navigate = useNavigate();

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     navigate('/patient');
//     console.log('Login submitted:', { username, password });
//   };

//   return (
//     <Container>
//       <form className={classes.form} onSubmit={handleSubmit}>
//         <Typography variant="h4" gutterBottom>
//           Login
//         </Typography>
//         <TextField
//           className={classes.textField}
//           label="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           fullWidth
//           required
//         />
//         <TextField
//           className={classes.textField}
//           label="Password"
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           fullWidth
//           required
//         />
//         <Button
//           className={classes.button}
//           type="submit"
//           variant="contained"
//           color="primary"
//           fullWidth
//         >
//           Login
//         </Button>
//         <Typography variant="body2" style={{ marginTop: '16px' }}>
//           Don't have an account? <Link to="/signup">Sign up</Link>
//         </Typography>
//       </form>
//     </Container>
//   );
// };

// export default Login;



import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('admin@gmail.com');
  const [password, setPassword] = useState('1234');

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/patient');
    console.log('Login submitted:', { username, password });
  };
  return (
    <>
      {/* <!-- Main Wrapper --> */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">

            {/* <!-- Login logo --> */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src="assets/img/login-02.png" alt="Logo" />
                </div>
              </div>
            </div>
            {/* <!-- /Login logo --> */}

            {/* <!-- Login Content --> */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <a href="index.html"><img src="assets/img/login-logo.png" alt="" /></a>
                      </div>
                      <h2>Login</h2>
                      {/* <!-- Form --> */}
                      <form onSubmit={handleSubmit}>
                        <div className="input-block">
                          <label >Email <span className="login-danger">*</span></label>
                          <input className="form-control" value={username}
                            onChange={(e) => setUsername(e.target.value)} type="text" />
                        </div>
                        <div className="input-block">
                          <label >Password <span className="login-danger">*</span></label>
                          <input value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control pass-input" type="password" />
                          <span className="profile-views feather-eye-off toggle-password"></span>
                        </div>
                        <div className="forgotpass">
                          {/* <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me"> Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark"></span>
                            </label>
                          </div> */}
                          {/* <a href="forgot-password.html">Forgot Password?</a> */}
                        </div>
                        <div className="input-block login-btn">
                          <button className="btn btn-primary btn-block" type="submit">Login</button>
                        </div>
                      </form>
                      {/* <!-- /Form --> */}

                      {/* <div className="next-sign">
                        <p className="account-subtitle">Need an account?  <a href="register.html">Sign Up</a></p> */}

                        {/* <!-- Social Login --> */}
                        {/* <div className="social-login">
                          <a href="javascript:;" ><img src="assets/img/icons/login-icon-01.svg" alt="" /></a>
                          <a href="javascript:;" ><img src="assets/img/icons/login-icon-02.svg" alt="" /></a>
                          <a href="javascript:;" ><img src="assets/img/icons/login-icon-03.svg" alt="" /></a>
                        </div> */}
                        {/* <!-- /Social Login --> */}

                      {/* </div> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/* <!-- /Login Content --> */}

          </div>
        </div>
      </div>
      {/* <!-- /Main Wrapper --> */}

    </>
  )
}

export default Login